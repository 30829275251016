import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { InformationComponent } from "./components/information.component";

const routes: Routes = [
  {
    path: "**",
    component: InformationComponent,
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InformationRoutingModule {}
