import { createAction, props } from "@ngrx/store";
import { StandardError } from "@shared/models/standard-error.model";
import { SectorExperience } from "@shared/models/sector-experience.model";
import { Interest } from "@shared/models/interest.model";
import { Expertise } from "@shared/models/expertise.model";
import { Location } from "@shared/models/location.model";
import { SignUpSelectOptions } from "@shared/models/sign-up-select-options.model";
import { UserSkills } from "@shared/models/user-skills.model";
import { CompanyRoles } from "@shared/models/company-roles.model";
import { SignupPlatformDto } from "@shared/api-client";

export const getSectorExperience = createAction(
  "[Select Options] Get Sector Experience",
);

export const getSectorExperienceSuccess = createAction(
  "[Select Options] Get Sector Experience Success",
  props<{
    data: SectorExperience[];
    message: string;
  }>(),
);

export const getSectorExperienceError = createAction(
  "[Select Options] Get Sector Experience Error",
  props<StandardError>(),
);

export const getLocation = createAction("[Select Options] Get Location");

export const getLocationSuccess = createAction(
  "[Select Options] Get Location Success",
  props<{
    data: Location[];
  }>(),
);

export const getLocationFailure = createAction(
  "[Select Options] Get Location Failure",
  props<StandardError>(),
);

export const getInterests = createAction("[Select Options] Get Interest");

export const getInterestsSuccess = createAction(
  "[Select Options] Get Interests Success",
  props<{
    data: Interest[];
    message: string;
  }>(),
);

export const getInterestsFailure = createAction(
  "[Select Options] Get Interests Failure",
  props<StandardError>(),
);

export const getExpertise = createAction("[Select Options] Get Expertise");

export const getExpertiseSuccess = createAction(
  "[Select Options] Get Expertise Success",
  props<{
    data: Expertise[];
    message: string;
  }>(),
);

export const getExpertiseFailure = createAction(
  "[Select Options] Get Expertise Failure",
  props<StandardError>(),
);

export const getSignupSelectOptions = createAction(
  "[Select Options] Get Signup Select Options",
  props<SignupPlatformDto>(),
);

export const getSignupSelectOptionsSuccess = createAction(
  "[Select Options] Get Signup Select Options Success",
  props<{
    data: SignUpSelectOptions;
  }>(),
);

export const getSignupSelectOptionsFailure = createAction(
  "[Select Options] Get Signup Select Options Failure",
  props<StandardError>(),
);

export const getUserSkills = createAction("[Select Options] Get User Skills");

export const getUserSkillsSuccess = createAction(
  "[Select Options] Get User Skills Success",
  props<{
    data: UserSkills;
  }>(),
);

export const getUserSkillsFailure = createAction(
  "[Select Options] Get User Skills Failure",
  props<StandardError>(),
);

export const getCompanyRoles = createAction(
  "[Select Options] Get Company Roles",
);

export const getSignupCompanyRoles = createAction(
  "[Signup Select Options] Get Signup Company Roles",
  props<SignupPlatformDto>(),
);

export const getCompanyRolesSuccess = createAction(
  "[Select Options] Get Company Roles Success",
  props<{
    data: CompanyRoles;
  }>(),
);

export const getCompanyRolesFailure = createAction(
  "[Select Options] Get Company Roles Failure",
  props<StandardError>(),
);
