import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { LogInComponent } from "./components/log-in/log-in.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { TakeYourPlaceComponent } from "@auth/components/sign-up/take-your-place-form/take-your-place.component";
import { SignUpApplicationCompleteComponent } from "@auth/components/sign-up/sign-up-application-complete/sign-up-application-complete.component";
import { BoardhouseFormComponent } from "@auth/components/sign-up/boardhouse-form/boardhouse-form.component";

const routes: Routes = [
  { path: "login", component: LogInComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "reset-password", component: ResetPasswordComponent },
  { path: "take-your-place", component: TakeYourPlaceComponent },
  { path: "boardhouse-sign-up", component: BoardhouseFormComponent },
  {
    path: "application-complete/:platform",
    component: SignUpApplicationCompleteComponent,
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
