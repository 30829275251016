import { routerReducer } from "@ngrx/router-store";
import { ActionReducer, ActionReducerMap, MetaReducer } from "@ngrx/store";
import { logout } from "src/app/modules/auth/store/auth.actions";

export interface GlobalState {
  // Global state
}

export const reducers: ActionReducerMap<GlobalState> = {
  router: routerReducer,
};

export const resetStateMetaReducer =
  (reducer: ActionReducer<any>): ActionReducer<any> =>
  (state, action) => {
    if (action.type === logout.type) {
      state = undefined;
    }

    return reducer(state, action);
  };

export const metaReducers: MetaReducer<GlobalState>[] = [resetStateMetaReducer];
