<nav class="navigation">
  <div class="header-items-container">
    <div (click)="landingRedirect()" class="logo-container">
      <div *ngIf="platformLogoFileName" class="logo">
        <img alt="logo" class="logo-image" [src]="platformLogoFileName" />
      </div>
    </div>

    <ng-container *ngIf="!isAuthenticated">
      <div class="sm:block lg:block">
        <div class="flex space-x-4">
          <a class="hyperlink" routerLink="/auth/login" type="button">
            Members sign in
          </a>
        </div>
      </div>
    </ng-container>
  </div>
</nav>
