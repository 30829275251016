import { NgModule } from "@angular/core";
import { FooterComponent } from "./components/footer.component";
import { SharedModule } from "@shared/shared.module";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { footerFeature } from "./store/footer.feature";
import { FooterEffects } from "./store/footer.effects";

@NgModule({
  imports: [
    SharedModule,
    StoreModule.forFeature(footerFeature),
    EffectsModule.forFeature([FooterEffects]),
  ],
  declarations: [FooterComponent],
  exports: [FooterComponent],
})
export class FooterModule {}
