import { createReducer, on } from "@ngrx/store";
import { initialFooterState } from "./footer.state";
import {
  loadingState,
  loadingStateSuccess,
} from "@shared/helpers/loading-state";
import { getFooterItemsReq, getFooterItemsSuccess } from "./footer.actions";

export const footerReducer = createReducer(
  initialFooterState,
  on(getFooterItemsReq, (state) => ({
    ...state,
    getFooterItems: loadingState(true),
  })),
  on(getFooterItemsSuccess, (state, payload) => ({
    ...state,
    getFooterItems: loadingStateSuccess(),
    footerItems: payload.footerItems,
  })),
);
