/**
 * A sort compare function to sort alphabetically.
 * Takes an optional highlight map to place those on top of the list alphabetically.
 * @param a The first element for comparison.
 * @param b The second element for comparison.
 * @param highlightMap
 * @private
 */
export const SortAlphabeticalFunction = (
  a: string,
  b: string,
  highlightMap?: { [key: string]: boolean },
): number => {
  if (highlightMap) {
    if (highlightMap[a] && highlightMap[b]) {
      return a < b ? -1 : 1;
    } else if (highlightMap[a]) {
      return -1;
    } else if (highlightMap[b]) {
      return 1;
    }
  }
  return a < b ? -1 : 1;
};
