import { createSelector } from "@ngrx/store";
import { AuthState } from "./auth.state";
import { selectAuthState } from "./auth.feature";
import { UserPlatform } from "@models";
import { selectUserProfile } from "@profile/store/profile.selectors";

export const selectLoginIsLoading = createSelector(
  selectAuthState,
  (state: AuthState) => state.login.loading,
);

export const selectLoginSuccess = createSelector(
  selectAuthState,
  (state: AuthState) => state.login.success,
);

export const selectLoginError = createSelector(
  selectAuthState,
  (state: AuthState) => state.login.error,
);

export const selectSignupIsLoading = createSelector(
  selectAuthState,
  (state: AuthState) => state.signup.loading,
);

export const selectSignupSuccess = createSelector(
  selectAuthState,
  (state: AuthState) => state.signup.success,
);

export const selectSignupError = createSelector(
  selectAuthState,
  (state: AuthState) => state.signup.error,
);

export const loggedIn = createSelector(
  selectAuthState,
  (state: AuthState) => state.loggedIn,
);

export const selectResetPasswordIsLoading = createSelector(
  selectAuthState,
  (state: AuthState) => state.resetPassword.loading,
);

export const selectResetPasswordSuccess = createSelector(
  selectAuthState,
  (state: AuthState) => state.resetPassword.success,
);

export const selectResetPasswordError = createSelector(
  selectAuthState,
  (state: AuthState) => state.resetPassword.error,
);

export const selectPermissions = createSelector(
  selectAuthState,
  (state: AuthState) => state.permissions,
);

export const selectIsUserPlatformIdMe = (userPlatformId: string) =>
  createSelector(selectAuthState, (state: AuthState) => {
    return state.user?.userPlatformId === userPlatformId;
  });

export const selectUserPlatformId = createSelector(
  selectAuthState,
  (state: AuthState) => state.user?.userPlatformId,
);

export const selectAuthUser = createSelector(
  selectAuthState,
  (state: AuthState) => state.user,
);

export const selectAuthInitialised = createSelector(
  selectAuthState,
  (state: AuthState) => state.firstLoad,
);

export const selectAuthenticated = createSelector(
  selectAuthState,
  (state: AuthState) => !!state.user?.userId,
);

export const selectCurrentPlatform = createSelector(
  selectUserProfile,
  selectUserPlatformId,
  (userProfile, userPlatformId) => {
    const currentUserPlatform: UserPlatform | undefined =
      getCurrentUserPlatform(userProfile.userPlatforms, userPlatformId);
    return currentUserPlatform?.platform.name;
  },
);

export const selectCurrentPlatformPaymentLink = createSelector(
  selectUserProfile,
  selectUserPlatformId,
  (userProfile, userPlatformId) => {
    const currentUserPlatform: UserPlatform | undefined =
      getCurrentUserPlatform(userProfile.userPlatforms, userPlatformId);
    return currentUserPlatform?.platform.paymentLink;
  },
);

export const selectCurrentPlatformPremium = createSelector(
  selectUserProfile,
  selectUserPlatformId,
  (userProfile, userPlatformId) => {
    const currentUserPlatform: UserPlatform | undefined =
      getCurrentUserPlatform(userProfile.userPlatforms, userPlatformId);
    return currentUserPlatform?.premium;
  },
);

/////////////////////
// Helper functions
/////////////////////

/**
 * Gets the current user platform
 * To be used by the selectCurrent platform
 * @param userPlatforms
 * @param currentUserPlatformId
 */

const getCurrentUserPlatform = (
  userPlatforms: UserPlatform[],
  currentUserPlatformId: string | undefined,
): UserPlatform | undefined => {
  return userPlatforms.find((userPlatform) => {
    if (userPlatform.userPlatformId === currentUserPlatformId) {
      return userPlatform;
    } else {
      return undefined;
    }
  });
};
