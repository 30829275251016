import { NgModule } from "@angular/core";
import { SearchBarComponent } from "@shared/modules/search-bar/components/search-bar.component";
import { FormsModule } from "@angular/forms";

@NgModule({
  declarations: [SearchBarComponent],
  exports: [SearchBarComponent],
  imports: [FormsModule],
})
export class SearchBarModule {}
