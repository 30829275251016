import { createAction, props } from "@ngrx/store";

class UnreadMessageCountDto {
  unreadCount: number;
}

const actionLabel = "[Message API]";

export const getUnreadMessageCount = createAction(
  `${actionLabel} Get Unread Message Count`,
);

export const getUnreadMessageCountSuccess = createAction(
  `${actionLabel} Get Unread Message Count Success`,
  props<UnreadMessageCountDto>(),
);

export const incrementUnreadMessage = createAction(
  `${actionLabel} Increment Unread Message Count`,
);
