import { Action, createReducer } from "@ngrx/store";
import { defaultPaginationState } from "@shared/store/models/pagination-entity-state.model";
import { GeneralEntityState } from "@shared/store/models/general-entity-state.model";
import { Message } from "@models";
import { ApiStateHelper } from "@shared/helpers/api-state.helper";
import { CreateChatMessageDto } from "@shared/api-client";
import { customMessageReducers } from "src/app/modules/member-root/modules/message/store/message.reducer";

export interface MessageState extends GeneralEntityState<Message> {
  // Insert custom state here
  unreadMessageCount: number;
}

// A helper class that extends the API State Helper with the right information
class MessageStateHelper extends ApiStateHelper<
  Message,
  CreateChatMessageDto,
  any,
  MessageState
> {
  constructor() {
    super("Message", "message", "messageId");
  }
}

export const MessageNgrx = new MessageStateHelper();
export const initialState: MessageState = MessageNgrx.getInitialState({
  unreadMessageCount: 0,
  pagination: {
    ...defaultPaginationState,
    pageSize: 20,
  },
});

export const messageReducer = createReducer(
  initialState,
  ...MessageNgrx.ons,
  ...MessageNgrx.apiOns,
  ...customMessageReducers,
);

export function reducer(
  state: MessageState = initialState,
  action: Action,
): MessageState {
  return messageReducer(state, action) as MessageState;
}
