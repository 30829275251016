import { loadingState, LoadingState } from "@shared/helpers/loading-state";
import { UserProfile } from "../models/user-profile.model";

export interface ProfileState {
  getUserProfile: LoadingState;
  userProfile: UserProfile;
  editUserDetails: LoadingState;
  editAboutDetails: LoadingState;
  getProfileForUser: LoadingState;
  editUserProfilePicture: LoadingState;
}

export let initialProfileState: ProfileState;
initialProfileState = {
  getUserProfile: loadingState(),
  editUserDetails: loadingState(),
  editAboutDetails: loadingState(),
  getProfileForUser: loadingState(),
  editUserProfilePicture: loadingState(),
  userProfile: {
    userId: "",
    userPlatformId: "",
    createdAt: new Date(),
    bio: "",
    name: "",
    location: {
      locationId: "",
      city: "",
      country: "",
    },
    expertise: [],
    interests: [],
    sectorExperience: [],
    details: {
      education: {
        school: "",
        degree: "",
      },
      secondLocation: {
        locationId: "",
        city: "",
        country: "",
      },
      gender: "",
      diversity: [],
      professionalFlags: {
        accountant: false,
        executive: false,
      },
      committeeChairmanships: {
        chairOfRemunerationCommittee: false,
        chairOfAuditCommittee: false,
        chairOfRiskCommittee: false,
        chairOfEsgCommittee: false,
        chairOfInvestmentCommittee: false,
      },
      preferences: {
        allowComments: false,
        allowDailyDigest: false,
        allowEquityResearch: false,
        allowMandates: false,
        allowMessages: true,
        allowWeeklyDigest: false,
        doNotDisturb: false,
        eventNotifications: false,
      },
    },
    firstName: "",
    middleName: "",
    lastName: "",
    personalEmail: "",
    phoneNumber: "",
    recoveryEmail: "",
    countryCode: "",
    userRoles: {},
    personalRemarks: "",
    groups: [],
    profilePicture: {
      fileId: "",
      name: "",
      fileName: "",
      mimeType: "",
      size: "",
    },
    showNumerableLink: false,
    integratedAdvisor: false,
    cvFile: {
      fileId: "",
      name: "",
      fileName: "",
      mimeType: "",
      size: "",
    },
    isMentor: false,
    mentorId: "",
    showRequestMentoringButton: false,
    platformHeaderTitle: "",
    userPlatforms: [],
    platformTheme: "",
    platformLogoName: "",
    remainingMentorCredits: 0,
    remainingMenteeCredits: 0,
    userProfileIncomplete: false,
  },
};
