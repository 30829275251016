import { Injectable } from "@angular/core";
import { Observable, Subject, takeUntil, timer } from "rxjs";
import { DateTime } from "luxon";
import { refreshToken } from "./store/auth.actions";
import { Team350Store } from "@shared/store/action-trackers/services/team-350.store";
import { select } from "@ngrx/store";
import { selectAuthenticated } from "src/app/modules/auth/store/auth.selectors";

// NB! Tokens are injected via the session
// and therefore does not need to manually be injected with an interceptor
@Injectable({
  providedIn: "root",
})
export class AuthService {
  /**
   * The number of seconds before the token expires to refresh the token.
   * @private
   */
  private readonly REFRESH_BEFORE_EXPIRY_BY_SECONDS = 30;

  unsubscribe$ = new Subject<void>();
  alive = true;
  constructor(private store: Team350Store) {
    // On startup, we check for an expiry date in the local storage.
    this.checkForExpiry();
  }

  private checkForExpiry() {
    const tokenExpiresAt = localStorage.getItem("tokenExpiresAt");
    if (tokenExpiresAt) {
      this.unsubscribe$.next();
      const expiresAt = DateTime.fromISO(tokenExpiresAt);
      const secondsUntilExpiry = expiresAt.diffNow().as("seconds");

      if (secondsUntilExpiry < 0) {
        // Token has expired.
        return this.store.dispatch(refreshToken());
      }

      const refreshIn = Math.floor(
        secondsUntilExpiry - this.REFRESH_BEFORE_EXPIRY_BY_SECONDS,
      );

      timer(refreshIn * 1000)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          this.store.dispatch(refreshToken());
        });
    }
  }

  isRefreshTokenExpired(): boolean {
    const refreshTokenExpiresAt = localStorage.getItem("refreshExpiresAt");
    if (refreshTokenExpiresAt) {
      const expiresAt = DateTime.fromISO(refreshTokenExpiresAt);
      const secondsUntilExpiry = expiresAt.diffNow().as("seconds");

      return secondsUntilExpiry < 0;
    }
    return true;
  }

  setTokenExpiration(expiresAt: string) {
    localStorage.setItem("tokenExpiresAt", expiresAt);
    this.checkForExpiry();
  }

  setRefreshTokenExpiration(expiresAt: string) {
    localStorage.setItem("refreshExpiresAt", expiresAt);
  }

  setToken(token: string) {
    localStorage.setItem("token", token);
  }

  getToken() {
    return localStorage.getItem("token");
  }

  setRefreshToken(token: string) {
    localStorage.setItem("refreshToken", token);
  }

  getRefreshToken() {
    return localStorage.getItem("refreshToken");
  }

  clearAllTokens() {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("tokenExpiresAt");
    localStorage.removeItem("refreshExpiresAt");
    this.unsubscribe$.next();
  }

  isAuthenticated(): Observable<boolean> {
    return this.store.pipe(select(selectAuthenticated));
  }
}
