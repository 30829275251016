<div class="auth-page-container">
  <div class="auth-form-container">
    <img
      class="logo-image"
      src="assets/images/platform-logos/350-club-infinity-dark.svg"
      alt="logo"
      (click)="landingRedirect()"
      style="cursor: pointer"
    />
    <app-general-card [centeredContent]="true">
      <h1 class="auth-form-title">Forgotten your password?</h1>
      <form class="form mb-6" [formGroup]="forgotPasswordForm">
        <div class="form-controls-container">
          <input
            class="secondary-input"
            type="text"
            formControlName="email"
            placeholder="Enter your email..."
          />
          <br />

          <div class="flex justify-center" *ngIf="environmentType">
            <ngx-recaptcha2
              #reCaptcha2Component
              [siteKey]="recaptchaKey"
              [useGlobalDomain]="false"
              size="normal"
              hl="en"
              theme="light"
              type="image"
              formControlName="recaptchaToken"
            >
            </ngx-recaptcha2>
          </div>

          <button
            [disabled]="!forgotPasswordForm.valid"
            class="primary-350-button"
            (click)="requestPasswordResetLink()"
          >
            Send password reset
          </button>
        </div>
      </form>
      <div *ngIf="displayMessage" class="success-text-box">
        <span class="success-text"
          >If this email is registered with us, <br />please check your inbox
          for the reset link</span
        >
      </div>
      <a routerLink="/auth/login" class="hyperlink">Sign in</a>
      <a routerLink="/auth/take-your-place" class="hyperlink">
        Apply to join the club</a
      >
    </app-general-card>
  </div>
</div>
