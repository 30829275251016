<div class="modal-template" style="z-index: 1000" #failureTemplate>
  <div class="relative bg-white w-full rounded-lg shadow dark:bg-gray-700">
    <button
      type="button"
      class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg"
      (click)="closeFailureModal()"
    >
      <svg
        class="w-3 h-3"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 14"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
        />
      </svg>
      <span class="sr-only">Close modal</span>
    </button>
    <div class="p-6 text-center">
      <svg
        class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
      <h1 class="mb-2 text-h2 text-error">{{ errorModalTitle }}</h1>
    </div>
    <div class="text-center flex flex-col items-center mb-4">
      <span class="text-body text-primary-chocolate">{{
        errorModalMessage
      }}</span>
      <ng-container *ngIf="retryVerification">
        <app-unverified-log-in-exception-retry [action]="errorAction" />
      </ng-container>
    </div>
  </div>
</div>

<div class="modal-template" style="z-index: 1000" #successTemplate>
  <div class="relative bg-white w-full rounded-lg shadow dark:bg-gray-700">
    <button
      type="button"
      class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg"
      (click)="closeSuccessModal()"
    >
      <svg
        class="w-3 h-3"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 14"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
        />
      </svg>
      <span class="sr-only">Close modal</span>
    </button>
    <div class="p-6 text-center">
      <svg
        class="w-12 h-12 text-primary-forest dark:text-gray-200 mx-auto mb-4"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m7 10 2 2 4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
      <h1 class="mb-2 text-h2 text-primary-forest">Success</h1>
    </div>
    <div class="modal-text-container">
      <span class="text-body text-primary-chocolate">{{
        successModalMessage
      }}</span>
      <br />
      <span class="text-body text-primary-chocolate">{{
        secondaryMessage
      }}</span>
      <div>
        <button class="primary-350-button" (click)="closeSuccessModal()">
          Continue
        </button>
      </div>
    </div>
  </div>
</div>
