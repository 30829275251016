import { SystemPageNameInterface } from "@shared/interfaces/system-page-name.interface";
import { frontendRoutes } from "@shared/config/frontend-routes.config";

export const sidebar: SystemPageNameInterface[] = [
  {
    systemPageName: "home-page",
    route: "/home",
  },
  {
    systemPageName: "directory-page",
    route: "/home/directory",
  },
  {
    systemPageName: "numerable-page",
    route: "/home/numerable",
  },
  {
    systemPageName: "equity-research",
    route: "/home/news/equity-research",
  },
  {
    systemPageName: "regulatory-news",
    route: "/home/news/regulatory-news",
  },
  {
    systemPageName: "corporate-news",
    route: "/home/news/corporate-news",
  },

  {
    systemPageName: "groups-page",
    route: "/home/groups",
  },
  {
    systemPageName: "events-page",
    route: "/home/events",
  },
  {
    systemPageName: "vacancies-page",
    route: "/home/vacancies",
  },
  {
    systemPageName: "ask-an-advisor-page",
    route: "home/advisors",
  },
  {
    systemPageName: "courses-page",
    route: "home/courses",
  },
  {
    systemPageName: "noticeboard-page",
    route: "home/noticeboard",
  },
  {
    systemPageName: "mentorship-page",
    route: frontendRoutes.mentorship,
    mentorRoute: frontendRoutes.mentorView,
    menteeRoute: frontendRoutes.menteeView,
  },
  {
    systemPageName: "survey-page",
    route: "home/survey",
  },
  {
    systemPageName: "subscription-page",
    route: "home/subscribe",
  },
];
