import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AuthClientApiService } from "@shared/api-client";
import {
  getMyAuthInfo,
  getMyAuthInfoSuccess,
  login,
  loginFailure,
  loginSuccess,
  logout,
  logoutSuccess,
  logoutUnauthorised,
  refreshToken,
  refreshTokenSuccess,
  requestForgotPasswordLink,
  resetPassword,
  resetPasswordFailure,
  resetPasswordSuccess,
  signup,
  signupSuccess,
  switchPlatform,
  switchPlatformFailure,
  switchPlatformSuccess,
  verifyUser,
  verifyUserSuccess,
} from "./auth.actions";
import { catchError, debounceTime, of, switchMap } from "rxjs";
import { Router } from "@angular/router";
import { AuthService } from "../auth.service";
import {
  completeActionTracker,
  failActionTracker,
} from "@shared/store/action-trackers/ngrx/action-tracker.actions";
import { Team350Store } from "@shared/store/action-trackers/services/team-350.store";
import { joinRooms } from "src/app/modules/realtime/store/realtime.actions";
import { getUnreadMessageCount } from "../../member-root/modules/message/store/message.actions";
import { getUnreadChatsReq } from "../../member-root/modules/chat/store/chat.actions";

@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      switchMap((action) =>
        this.authClientApiService
          .authControllerLogin({
            email: action.email,
            password: action.password,
            recaptchaToken: action.recaptchaToken,
          })
          .pipe(
            switchMap((response) => {
              this.authService.setTokenExpiration(
                response.data.token.accessExpiresAt,
              );

              this.authService.setRefreshTokenExpiration(
                response.data.token.refreshExpiresAt,
              );

              this.authService.setToken(response.data.token.accessToken);
              this.authService.setRefreshToken(
                response.data.token.refreshToken,
              );

              // Chat updates
              this.store.dispatch(getUnreadMessageCount());
              this.store.dispatch(getUnreadChatsReq());

              return [
                loginSuccess(response.data),
                joinRooms(),
                completeActionTracker({
                  action,
                  data: response.data,
                }),
              ];
            }),
            catchError((error) =>
              of(loginFailure({ error, message: error?.error.message })),
            ),
          ),
      ),
    ),
  );

  signup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signup),
      switchMap((action) =>
        this.authClientApiService.authControllerSignup(action.signupDto).pipe(
          debounceTime(1000),
          switchMap((response) => {
            return [
              signupSuccess(response.data),
              completeActionTracker({
                action,
                data: response.data,
              }),
            ];
          }),
          catchError((error) =>
            of(
              failActionTracker({
                action,
                data: { error, message: error?.error.message },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      switchMap((action) =>
        this.authClientApiService.authControllerLogout().pipe(
          switchMap((response) => {
            this.authService.clearAllTokens();
            this.router.navigate(["/auth/login"]);
            return [
              logoutSuccess(),
              completeActionTracker({ action, data: null }),
            ];
          }),
          catchError((error) => {
            this.authService.clearAllTokens();
            this.router.navigate(["/auth/login"]);
            return of(
              failActionTracker({
                action,
                data: { error, message: error?.error.message },
              }),
            );
          }),
        ),
      ),
    ),
  );

  logoutUnauthorised$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutUnauthorised),
      switchMap((action) =>
        this.authClientApiService.authControllerLogoutUnauthorised().pipe(
          switchMap((response) => {
            this.authService.clearAllTokens();
            this.router.navigate(["/auth/login"]);
            return [logoutSuccess()];
          }),
          catchError((error) => {
            this.authService.clearAllTokens();
            this.router.navigate(["/auth/login"]);
            return of(logoutSuccess());
          }),
        ),
      ),
    ),
  );

  requestResetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestForgotPasswordLink),
      switchMap((action) =>
        this.authClientApiService
          .authControllerForgotPassword({
            email: action.email,
            recaptchaToken: action.recaptchaToken,
          })
          .pipe(
            switchMap(() => {
              return [completeActionTracker({ action, data: null })];
            }),
            catchError((error) => {
              return of(
                failActionTracker({
                  action,
                  data: { error, message: error?.error.message },
                }),
              );
            }),
          ),
      ),
    ),
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetPassword),
      switchMap((action) =>
        this.authClientApiService
          .authControllerResetPassword({
            password: action.password,
            token: action.token,
          })
          .pipe(
            switchMap((response) => {
              return [
                resetPasswordSuccess(),
                completeActionTracker({ action, data: null }),
              ];
            }),
            catchError((error) => {
              return [
                resetPasswordFailure(error),
                failActionTracker({
                  action,
                  data: { error, message: error?.error.message },
                }),
              ];
            }),
          ),
      ),
    ),
  );

  requestRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshToken),
      switchMap((action) => {
        const token = this.authService.getRefreshToken() || "";
        return this.authClientApiService.authControllerRefresh({ token }).pipe(
          switchMap((response) => {
            this.authService.setTokenExpiration(response.data.accessExpiresAt);
            this.authService.setToken(response.data.accessToken);

            return [
              refreshTokenSuccess({
                accessExpiresAt: response.data.accessExpiresAt,
              }),
              completeActionTracker({ action, data: null }),
            ];
          }),
          catchError((error) => {
            return of(
              failActionTracker({
                action,
                data: { error, message: error?.error?.message },
              }),
            );
          }),
        );
      }),
    ),
  );

  getMyAuthInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMyAuthInfo),
      switchMap((action) =>
        this.authClientApiService.authControllerGetCurrentUser().pipe(
          switchMap((response) => {
            return [
              getMyAuthInfoSuccess(response.data),
              completeActionTracker({ action, data: response.data }),
            ];
          }),
          catchError((error) =>
            of(
              failActionTracker({
                action,
                data: { error, message: error?.error?.message },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  verifyUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(verifyUser),
      switchMap((action) =>
        this.authClientApiService
          .authControllerVerifyUser({
            verificationToken: action.verificationToken,
          })
          .pipe(
            switchMap((response) => {
              return [
                verifyUserSuccess(),
                completeActionTracker({ action, data: response.data }),
              ];
            }),
            catchError((error) =>
              of(
                failActionTracker({
                  action,
                  data: { error, message: error?.error?.message },
                }),
              ),
            ),
          ),
      ),
    ),
  );

  switchPlatform$ = createEffect(() =>
    this.actions$.pipe(
      ofType(switchPlatform),
      switchMap((action) =>
        this.authClientApiService
          .authControllerSwitchPlatform({
            platformName: action.platformName,
          })
          .pipe(
            switchMap((response) => {
              this.authService.setTokenExpiration(
                response.data.token.accessExpiresAt,
              );

              this.authService.setRefreshTokenExpiration(
                response.data.token.refreshExpiresAt,
              );

              this.authService.setToken(response.data.token.accessToken);
              this.authService.setRefreshToken(
                response.data.token.refreshToken,
              );

              // Chat updates
              this.store.dispatch(getUnreadMessageCount());
              this.store.dispatch(getUnreadChatsReq());

              return [
                switchPlatformSuccess(response.data),
                joinRooms(),
                completeActionTracker({
                  action,
                  data: response.data,
                }),
              ];
            }),
            catchError((error) =>
              of(
                switchPlatformFailure({ error, message: error?.error.message }),
              ),
            ),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private authClientApiService: AuthClientApiService,
    private authService: AuthService,
    private router: Router,
    private store: Team350Store,
  ) {}
}
