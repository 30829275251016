import { createAction, props } from "@ngrx/store";
import {
  BatchCreateDto,
  BatchIdDto,
  BatchUpdateDto,
  SingleIdDto,
} from "@shared/dtos/actions-request.dto";

export class DefaultRequestActions<Entity, CreateDto, UpdateDto> {
  constructor(public name: string) {}

  findAllReq = createAction(`[${this.name} API] Find All`);
  findOneReq = createAction(
    `[${this.name} API] Find One`,
    props<SingleIdDto>(),
  );

  searchReq = createAction(`[${this.name} API] Search`); // Props are pulled from store

  createOneReq = createAction(
    `[${this.name} API] Create One`,
    props<{ dto: CreateDto }>(),
  );
  createManyReq = createAction(
    `[${this.name} API] Create Many`,
    props<{ dto: BatchCreateDto<CreateDto> }>(),
  );

  updateOneReq = createAction(
    `[${this.name} API] Update One`,
    props<{ id: number; dto: UpdateDto }>(),
  );
  updateManyReq = createAction(
    `[${this.name} API] Update Many`,
    props<{ dto: BatchUpdateDto<UpdateDto> }>(),
  );

  removeOneReq = createAction(
    `[${this.name} API] Remove One`,
    props<SingleIdDto>(),
  );
  removeManyReq = createAction(
    `[${this.name} API] Remove Many`,
    props<BatchIdDto>(),
  );
}
