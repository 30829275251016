// TODO: Find and replace all places with config routes
export const frontendRoutes = {
  home: "/home",
  groups: "/home/groups",
  profile: "/home/profile",
  events: "/home/events",
  directory: "/home/directory",
  numerable: "/home/numerable",
  vacancies: "/home/vacancies",
  userMessages: "/home/messages",
  equityResearch: "/home/news/equity-research",
  regulatoryNews: "/home/news/regulatory-news",
  corporateNews: "/home/news/corporate-news",
  advisors: "/home/advisors",
  mentorship: "/home/mentoring",
  mentorView: "/home/mentoring/mentor",
  menteeView: "/home/mentoring/mentee",
  mentorApply: "/home/mentoring/mentor/apply",
  menteeApply: "/home/mentoring/mentee/apply",
  mentorDecline: "/home/mentoring/mentor/decline-application",
  mentorReview: "/home/mentoring/mentor/review-application",
  menteeFeedback: "/home/mentoring/mentee/feedback",
};
