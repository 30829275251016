import { loadingState, LoadingState } from "@shared/helpers/loading-state";
import { FooterModel } from "@models";

export interface FooterState {
  getFooterItems: LoadingState;
  footerItems: FooterModel[];
}

export const initialFooterState: FooterState = {
  getFooterItems: loadingState(),
  footerItems: [],
};
