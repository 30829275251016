import { Component, OnDestroy, OnInit } from "@angular/core";
import { StaticPageDetails } from "@shared/interfaces/static-page-details.interface";
import EditorJS from "@editorjs/editorjs";
import { SystemPageNameInterface } from "@shared/interfaces/system-page-name.interface";
import { filter, Subject, takeUntil } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import {
  Team350Store,
  TrackingHelpers,
} from "@shared/store/action-trackers/services/team-350.store";
import { isEmpty, isNil } from "lodash";
import { getFooterItemsReq } from "../../footer/store/footer.actions";
import { selectFooterItems } from "../../footer/store/footer.selectors";
import { FooterModel } from "@models";
import { config } from "@config";
// @ts-ignore
import Header from "@editorjs/header";
// @ts-ignore
import List from "@editorjs/list";
// @ts-ignore
import LinkTool from "@editorjs/link";

@Component({
  selector: "app-information",
  templateUrl: "./information.component.html",
  styleUrls: ["./information.component.scss"],
})
export class InformationComponent implements OnInit, OnDestroy {
  pageDetails: StaticPageDetails;
  editorJsId: string = `privacy-policy-editor-js-content`;
  editor: EditorJS;
  platformName: string;
  private currentPageNameAndRoute: SystemPageNameInterface;
  private NOT_FOUND_MESSAGE: string = `The page you are looking for was not found`;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  platformLogoFileName: string;
  platformTheme: string;
  protected readonly config = config;
  private getFooterItemsAction$: TrackingHelpers;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Team350Store,
  ) {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        this.platformName = params["platform"];
      });
  }

  ngOnInit() {
    if (!isNil(this.router.getCurrentNavigation()?.extras?.state)) {
      this.pageDetails = this.router.getCurrentNavigation()?.extras
        .state as StaticPageDetails;
      this.initialiseEditor();
    } else {
      this.getFooterItemsAction$ = this.store.trackDispatch(
        getFooterItemsReq({ platform: this.platformName }),
      );

      this.getFooterItemsAction$.onComplete().subscribe({
        next: () => this.setupSubscriptionToFooterItems(),
      });
    }

    this.setPlatformDetails();
  }

  private setPlatformDetails() {
    switch (this.platformName) {
      case config.platforms.team350Club:
        this.platformTheme = config.platformThemes.team350Club;
        this.platformLogoFileName = `/assets/images/platform-logos/350-club-infinity-light.svg`;
        break;
      case config.platforms.boardhouse:
        this.platformTheme = config.platformThemes.boardhouse;
        this.platformLogoFileName = `/assets/images/platform-logos/board-house-logo-dark.svg`;
    }
  }

  private initialiseEditor(): void {
    if (this.editor || isNil(this.pageDetails?.content)) {
      // Taking the first one because destroying cannot be done while the editor is still loading
      return;
    }
    this.editor = new EditorJS({
      holder: this.editorJsId,
      data: this.pageDetails?.content,
      readOnly: true,
      tools: {
        header: Header,
        list: List,
        linkTool: LinkTool,
      },
      onReady: () => {
        this._stretchEditorJsBlocks(this.editor);
      },
    });
  }

  private setupSubscriptionToFooterItems(): void {
    this.getCurrentPageNameAndRoute();
    this.store
      .select(selectFooterItems)
      .pipe(filter((footerItems: FooterModel[]) => !isEmpty(footerItems)))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (footerItems: FooterModel[]) => {
          this.resetPageDetails(footerItems);
        },
      });
  }

  private getCurrentPageNameAndRoute(): void {
    const currentRoute: string = this.router.url;
    this.currentPageNameAndRoute = config.footer.find(
      (currentPageNameAndRoute: SystemPageNameInterface): boolean =>
        currentPageNameAndRoute.route === currentRoute,
    ) as SystemPageNameInterface;

    // We don't want this to fail just because it is not in the config, if it exists, create it and it can fail if the entity doesn't exist
    if (isEmpty(this.currentPageNameAndRoute)) {
      const pageName: string = currentRoute.slice(
        currentRoute.lastIndexOf("/") + 1,
      );
      this.currentPageNameAndRoute = {
        route: currentRoute,
        systemPageName: pageName,
      };
    }
  }

  private resetPageDetails(footerItems: FooterModel[]): void {
    const currentPageItem = footerItems.find(
      (footerItem: FooterModel) =>
        (footerItem.systemPageName as string) ===
        this.currentPageNameAndRoute?.systemPageName.split("?")[0],
    );

    this.pageDetails = isNil(currentPageItem)
      ? { title: this.NOT_FOUND_MESSAGE, content: {} }
      : {
          title: currentPageItem.title,
          content: currentPageItem.content,
        };
    this.initialiseEditor();
  }

  // TODO: Update this once courses is merged in
  private _stretchEditorJsBlocks(editor: EditorJS) {
    if (!isNil(editor.blocks)) {
      for (let i = 0; i < editor.blocks.getBlocksCount(); i++) {
        const block = editor.blocks.getBlockByIndex(i);
        if (!isNil(block)) {
          block.stretched = true;
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.editor) {
      this.editor.destroy();
    }
  }
}
