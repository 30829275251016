import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { config } from "@config";
import { getErrorMessage } from "@shared/helpers/form-error-message";
import { ActionsSubject, select } from "@ngrx/store";
import { ofType } from "@ngrx/effects";
import {
  getSignupCompanyRoles,
  getSignupSelectOptions,
  getSignupSelectOptionsSuccess,
} from "../../../../select-options/store/select-options.actions";
import { Subject, takeUntil } from "rxjs";
import { GenericDropdownOptions } from "@shared/models/generic-dropdown-options.model";
import { signup, signupSuccess } from "@auth/store/auth.actions";
import { SignupModel } from "@auth/models/requests/signup.model";
import { phoneNumberValidator } from "@shared/validator/phone-number.validator";
import { CountryCode } from "libphonenumber-js";
import { Router } from "@angular/router";
import {
  Team350Store,
  TrackingHelpers,
} from "@shared/store/action-trackers/services/team-350.store";
import { isNil } from "lodash";
import { selectCompanyRoles } from "../../../../select-options/store/select-options.selectors";
import {
  ftseCompanyFormControlName,
  ftseCompanyValidator,
  isFtseCompanyBooleanFormControlName,
  nonFtseCompanyFormControlName,
} from "@shared/validator/ftse-company.validator";
import { DeviceDetectorService } from "ngx-device-detector";
import { SeniorityOptions } from "@shared/enums/seniority-options.enum";

@Component({
  selector: "app-boardhouse-form",
  templateUrl: "./boardhouse-form.component.html",
  styleUrls: ["./boardhouse-form.component.scss"],
})
export class BoardhouseFormComponent implements OnInit, OnDestroy {
  getErrorMessage = getErrorMessage;
  signupForm: FormGroup;
  countryCodes = config.countryCodes.countries;
  diversity = Object.values(config.signUpDiversity);
  formattedCountryCodes: GenericDropdownOptions[] = [];
  destroyed$ = new Subject<void>();

  defaultLocation: GenericDropdownOptions | undefined;

  selectOptionAction: TrackingHelpers;
  signUpAction!: TrackingHelpers;

  sortedCompanyRoles: GenericDropdownOptions[];
  sortedCompanies: GenericDropdownOptions[];
  statusOption: GenericDropdownOptions[] = config.profile.companyStatus;
  genderOptions: string[] = config.genderOptions;
  secondaryRoleAddition: boolean = false;
  isOtherSeniority: boolean;
  isCountrySelected: boolean;

  sectorExperience: GenericDropdownOptions[] = [];
  locations: GenericDropdownOptions[] = [];
  allLocations: GenericDropdownOptions[] = [];
  interests: GenericDropdownOptions[] = [];
  expertise: GenericDropdownOptions[] = [];
  languages: GenericDropdownOptions[] = [];
  seniorityOptions: GenericDropdownOptions[] = [];
  countries: GenericDropdownOptions[];
  countryCities: GenericDropdownOptions[];
  pageNumber: number = 1;
  diversityDisclosed: boolean = false;
  isMobileView: boolean = this.deviceService.isMobile();
  platformName: string = config.platforms.boardhouse;
  frontendPlatformName: string = config.platforms.boardhouse
    .split("-")[0]
    .trim();
  private locationDictionary: { [country: string]: GenericDropdownOptions[] };

  constructor(
    private formBuilder: FormBuilder,
    private store: Team350Store,
    private actionsSubject: ActionsSubject,
    private router: Router,
    private deviceService: DeviceDetectorService,
  ) {
    this.initialiseForm();
    this.addPhoneNumberValidation();
    this.addDiversityFormArray();
    this.updateLocationList();
    this.confirmEmailValidation();
    this.confirmPasswordValidation();
  }

  get location() {
    return this.signupForm.controls["location"] as FormArray;
  }

  get locationsFormGroup() {
    return this.location.controls as FormGroup[];
  }

  get personalDetailsValid(): boolean {
    return (
      this.signupForm.controls["firstName"].valid &&
      this.signupForm.controls["lastName"].valid &&
      this.signupForm.controls["email"].valid &&
      this.signupForm.controls["confirmEmail"].valid &&
      this.signupForm.controls["countryCode"].valid &&
      this.signupForm.controls["cellphoneNumber"].valid &&
      this.signupForm.controls["location"].valid &&
      this.signupForm.controls["languages"].valid &&
      this.signupForm.controls["password"].valid &&
      this.signupForm.controls["confirmPassword"].valid
    );
  }

  landingRedirect() {
    this.router.navigate(["/landing"]);
  }

  gotoNextPage() {
    window.scroll({
      top: 0,
      left: 0,
    });
    if (!this.personalDetailsValid) {
      this.signupForm.controls["firstName"].markAsTouched();
      this.signupForm.controls["lastName"].markAsTouched();
      this.signupForm.controls["email"].markAsTouched();
      this.signupForm.controls["confirmEmail"].markAsTouched();
      this.signupForm.controls["countryCode"].markAsTouched();
      this.signupForm.controls["cellphoneNumber"].markAsTouched();
      this.signupForm.controls["location"].markAllAsTouched();
      this.signupForm.controls["languages"].markAsTouched();
      this.signupForm.controls["password"].markAsTouched();
      this.signupForm.controls["confirmPassword"].markAsTouched();
      return;
    }
    this.pageNumber = 2;
  }

  gotoPreviousPage() {
    window.scroll({
      top: 0,
      left: 0,
    });
    this.pageNumber = 1;
  }

  confirmEmailValidation() {
    this.signupForm.get("email")?.valueChanges.subscribe(() => {
      if (
        this.signupForm.get("confirmEmail")?.touched &&
        this.signupForm.get("confirmEmail")?.dirty
      ) {
        this.signupForm.get("confirmEmail")?.updateValueAndValidity();
      }
    });
  }
  confirmPasswordValidation() {
    this.signupForm.get("password")?.valueChanges.subscribe(() => {
      if (
        this.signupForm.get("confirmPassword")?.touched &&
        this.signupForm.get("confirmPassword")?.dirty
      ) {
        this.signupForm.get("confirmPassword")?.updateValueAndValidity();
      }
    });
  }

  addPhoneNumberValidation() {
    this.signupForm.get("countryCode")?.valueChanges.subscribe(() => {
      const countryCode: CountryCode = this.signupForm.get("countryCode")
        ?.value[0].countryCode as CountryCode;

      this.signupForm.get("cellphoneNumber")?.clearValidators();
      this.signupForm
        .get("cellphoneNumber")
        ?.setValidators([
          Validators.required,
          phoneNumberValidator(countryCode),
        ]);
    });

    this.signupForm.get("cellphoneNumber")?.valueChanges.subscribe(() => {
      const countryCode = this.signupForm.get("countryCode")?.value;

      if (isNil(countryCode)) {
        this.signupForm
          .get("countryCode")
          ?.setErrors({ required: true }, { emitEvent: true });
        this.signupForm.get("countryCode")?.markAsTouched();
      }
    });
  }

  ngOnInit(): void {
    this.getRoleCompanyOptions();
    this.getSignupOptions();
    this.onSignupSuccess();
    this.onSelectDiversity();
    this.formatCountryCodes();
    this.onSelectOtherSeniority();
  }

  defaultLanguage() {
    const defaultLanguage = this.languages.find(
      (language) => language.displayName === "English",
    );
    this.signupForm.controls["languages"].patchValue([defaultLanguage]);
  }

  addDiversityFormArray(): void {
    this.diversity.map((option: string): void => {
      const control = new FormControl(null);
      (this.signupForm.controls["diversity"] as FormArray).push(control);
    });
  }

  defaultUnitedKingdom() {
    const uk = this.locations.find(
      (location) => location.displayName === "London - United Kingdom",
    );
    this.locationsFormGroup[0].controls["location"].patchValue([uk]);
  }

  addLocation() {
    const locationForm = this.formBuilder.group({
      country: [null, [Validators.required]],
      city: [null, [Validators.required]],
    });

    this.location.push(locationForm);
    this.countryCities = [];
    this.onSelectCountry();
  }

  removeLocation(index: number) {
    if (this.location.length >= 1) {
      this.location.removeAt(index);
    }
  }

  // ---------------------------- LOCATION ----------------------------------

  onSelectCountry() {
    this.locationsFormGroup.forEach((location) => {
      location.controls["country"].valueChanges.subscribe((country) => {
        if (country) {
          location.controls["city"].patchValue(null); //reset city when you select a country
          this.isCountrySelected = true;
          this.countryCities = this.locationDictionary[country[0].value];
        }
      });
    });
  }

  updateLocationList() {
    this.location.valueChanges.subscribe((locationFormArray: any[]) => {
      const locationValues: string[] = locationFormArray.map(
        (locationArray) => {
          if (locationArray.location) {
            return locationArray.location[0].value;
          }
        },
      );
      this.locations = this.allLocations.filter(
        (locations) => !locationValues.includes(locations.value),
      );
    });
  }

  formatCountryCodes() {
    this.formattedCountryCodes = this.countryCodes.map((code) => {
      return {
        checked: false,
        displayName: this.isMobileView
          ? `${code.dial_code}`
          : `${code.flag} ${code.dial_code}`,
        value: code.dial_code,
        optionName: `${code.flag} ${code.name} ${code.dial_code}`,
        countryCode: code.code,
      };
    });
  }

  submitForm() {
    const signupFormValues = this.signupForm.value;
    const isFTSECompany = signupFormValues.isFTSECompany;
    const isSecondaryFTSECompany = signupFormValues.isSecondaryFTSECompany;

    let diversityValue = signupFormValues.diversity
      .map((v: string, i: number) => (v ? this.diversity[i] : null))
      .filter((v: string) => v !== null);
    if (!isNil(this.signupForm.controls["diversityUndisclosed"].value)) {
      diversityValue = [this.signupForm.controls["diversityUndisclosed"].value];
    }

    const locations = signupFormValues.location.map((local: any) => {
      return local.city[0];
    });

    const role: string = signupFormValues.role;

    let company: GenericDropdownOptions[] = signupFormValues.company;

    // TODO [TEAM350-1845]: Should we allow for the second company to not be FTSE?
    //  If we don't allow it, then we need to stop users from being able to add a
    //  secondary role if their primary is not FTSE. And then remove the field
    //  for the secondary role's 'isFTSE' boolean check.
    //  If we do allow it, then we will need to update what information is sent
    //  to the backend.
    // This assumes that the secondary company exists only when primary company exists. It can not exist alone
    if (isFTSECompany && isSecondaryFTSECompany) {
      company = signupFormValues.secondaryCompany
        ? [...signupFormValues.company, ...signupFormValues.secondaryCompany]
        : [...signupFormValues.company];
    }

    const signUpDto: SignupModel = {
      ...this.signupForm.value,
      countryCode: signupFormValues.countryCode[0].value,
      diversity: diversityValue,
      location: locations,
      role: role,
      company: company,
      platform: this.platformName,
      countryCodeLetters:
        this.signupForm.get("countryCode")?.value[0].countryCode,
      seniority: this.isOtherSeniority
        ? this.signupForm.controls["otherSeniority"].value
        : this.signupForm.controls["seniority"].value[0].value,
    };

    this.signUpAction = this.store.trackDispatch(
      signup({ signupDto: signUpDto }),
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  confirmationEmailValidator = (
    control: FormControl,
  ): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.signupForm.controls["email"].value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.signupForm.controls["password"].value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  showSecondaryRoleField() {
    this.secondaryRoleAddition = !this.secondaryRoleAddition;
  }

  private initialiseForm() {
    this.signupForm = this.formBuilder.group(
      {
        firstName: new FormControl(null, [Validators.required]),
        middleName: new FormControl(null, []),
        lastName: new FormControl(null, [Validators.required]),
        email: new FormControl(null, [
          Validators.required,
          Validators.pattern(config.regex.email),
        ]),
        confirmEmail: new FormControl(null, [
          Validators.required,
          Validators.pattern(config.regex.email),
          this.confirmationEmailValidator,
        ]),
        recoveryEmail: new FormControl(null, [
          Validators.pattern(config.regex.email),
        ]),
        password: new FormControl(null, [
          Validators.required,
          Validators.pattern(config.regex.strongPassword),
        ]),
        confirmPassword: new FormControl(null, [
          Validators.required,
          this.confirmationValidator,
        ]),
        cellphoneNumber: new FormControl(null, [Validators.required]),
        countryCode: new FormControl(null, [Validators.required]),
        gender: new FormControl(null, [Validators.required]),
        diversity: this.formBuilder.array([]),
        diversityUndisclosed: new FormControl(null),
        location: this.formBuilder.array([
          this.formBuilder.group({
            city: [this.defaultLocation, [Validators.required]],
            country: new FormControl(null, [Validators.required]),
          }),
        ]),
        sectorExperience: new FormControl(null, [Validators.required]),
        expertise: new FormControl(null, [Validators.required]),
        interests: new FormControl(null, [Validators.required]),
        languages: new FormControl([], [Validators.required]),
        role: new FormControl(null, [Validators.required]),
        [ftseCompanyFormControlName]: new FormControl(null),
        [nonFtseCompanyFormControlName]: new FormControl(null),
        [isFtseCompanyBooleanFormControlName]: new FormControl(null, [
          Validators.required,
        ]),
        status: new FormControl(null, [Validators.required]),
        secondaryRole: new FormControl(null),
        secondaryCompany: new FormControl(null),
        secondaryNonFTSECompanyName: new FormControl(null),
        isSecondaryFTSECompany: new FormControl(null),
        secondaryStatus: new FormControl(null),
        executive: new FormControl(null, [Validators.required]),
        accountant: new FormControl(null, [Validators.required]),
        acceptTerms: new FormControl(null, [Validators.requiredTrue]),
        seniority: new FormControl(null, [Validators.required]),
        otherSeniority: new FormControl(null),
      },
      {
        validators: [
          ftseCompanyValidator(
            ftseCompanyFormControlName,
            nonFtseCompanyFormControlName,
            isFtseCompanyBooleanFormControlName,
          ),
          ftseCompanyValidator(
            "secondaryCompany",
            "secondaryNonFTSECompanyName",
            "isSecondaryFTSECompany",
          ),
        ],
      },
    );
  }

  private onSelectDiversity() {
    this.signupForm.controls["diversity"].valueChanges.subscribe((value) => {
      if (value.indexOf(true) !== -1) {
        this.signupForm.controls["diversityUndisclosed"].setValue(false);
        this.signupForm.controls["diversityUndisclosed"].disable();
        this.diversityDisclosed = true;
      } else {
        this.signupForm.controls["diversityUndisclosed"].enable();
      }
    });
  }

  private onSignupSuccess() {
    this.actionsSubject
      .pipe(ofType(signupSuccess), takeUntil(this.destroyed$))
      .subscribe((result) => {
        if (!isNil(result.redirectUrl)) {
          this.router.navigate([result.redirectUrl]);
          return;
        }
        this.router.navigate([
          `/auth/application-complete/${this.frontendPlatformName}`,
        ]);
      });
  }

  private getSignupOptions() {
    this.selectOptionAction = this.store.trackDispatch(
      getSignupSelectOptions({ platformName: this.platformName }),
    );
    this.actionsSubject
      .pipe(ofType(getSignupSelectOptionsSuccess), takeUntil(this.destroyed$))
      .subscribe((result) => {
        this.sectorExperience = result.data.sectorExperience;
        this.locations = result.data.locations;
        this.expertise = result.data.expertise;
        this.interests = result.data.interests;
        this.languages = result.data.languages;
        this.allLocations = result.data.locations;
        this.seniorityOptions = result.data.seniorityOptions;
        this.countries = result.data.countries;
        this.locationDictionary = result.data.locationDictionary;
        this.defaultLanguage();
        this.onSelectCountry();
      });
  }

  private getRoleCompanyOptions() {
    this.store.trackDispatch(
      getSignupCompanyRoles({ platformName: this.platformName }),
    );
    this.store
      .pipe(select(selectCompanyRoles), takeUntil(this.destroyed$))
      .subscribe((result) => {
        this.sortedCompanies = result.company.slice();
        this.sortedCompanyRoles = result.role.slice();
      });
  }

  private onSelectOtherSeniority() {
    this.signupForm.controls["seniority"].valueChanges.subscribe((value) => {
      this.isOtherSeniority = value[0].value === SeniorityOptions.other;
    });
  }
}
