import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { realtimeFeature } from "src/app/modules/realtime/store/realtime.feature";
import { RealtimeEffects } from "src/app/modules/realtime/store/realtime.effects";
import { SharedModule } from "@shared/shared.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature(realtimeFeature),
    EffectsModule.forFeature([RealtimeEffects]),
  ],
})
export class RealtimeModule {}
