import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  concatMap,
  map,
  of,
  switchMap,
  withLatestFrom,
} from "rxjs";
import {
  completeActionTracker,
  failActionTracker,
} from "@shared/store/action-trackers/ngrx/action-tracker.actions";
import {
  joinRooms,
  joinRoomsSuccess,
  setSocketId,
} from "src/app/modules/realtime/store/realtime.actions";
import { RealtimeClientApiService } from "@shared/api-client";
import { selectSocketId } from "src/app/modules/realtime/store/realtime.selectors";
import { select } from "@ngrx/store";
import { Team350Store } from "@shared/store/action-trackers/services/team-350.store";

@Injectable()
export class RealtimeEffects {
  setSocketId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setSocketId),
      map((action) => joinRooms()),
    ),
  );

  joinRooms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(joinRooms),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(selectSocketId))),
        ),
      ),
      switchMap(([action, socketId]) =>
        this.realtimeClientApiService
          .realtimeControllerJoinMyRooms({ socketId })
          .pipe(
            switchMap((response) => {
              return [
                joinRoomsSuccess(),
                completeActionTracker({
                  action,
                  data: response.data,
                }),
              ];
            }),
            catchError((error) =>
              of(
                failActionTracker({
                  action,
                  data: { error, message: error?.error.message },
                }),
              ),
            ),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private realtimeClientApiService: RealtimeClientApiService,
    private store: Team350Store,
  ) {}
}
