<div
  [ngClass]="platformTheme ? platformTheme : config.platformThemes.team350Club"
  class="about-page"
>
  <div class="content-container">
    <div class="header">
      <logged-out-header
        [platformLogoFileName]="platformLogoFileName"
      ></logged-out-header>
    </div>
    <div class="page-content">
      <div class="info-container">
        <div class="info-card">
          <div class="image-container">
            <img
              alt="350 building"
              class="header-logo"
              src="assets/images/350-building.png"
            />
          </div>
          <span class="text-body"
            ><h1 *ngIf="!!pageDetails">{{ pageDetails.title }}</h1>
            <p [id]="editorJsId"></p>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
