import { Component, Input } from "@angular/core";
import { config } from "@config";

@Component({
  selector: "app-sign-up-form-stepper",
  templateUrl: "./sign-up-form-stepper.component.html",
  styleUrls: ["./sign-up-form-stepper.component.scss"],
})
export class SignUpFormStepperComponent {
  @Input() pageNumber: number;
  @Input() platformName: string;

  protected readonly platformType = config.platforms;
}
