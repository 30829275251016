<!--TODO: Create a general component within the take you place module
      this component will extract the layout of the take your place form (i.e the logo header
      and take-your-place-container class)
 -->
<div
  class="take-your-place-container"
  [ngClass]="isBoardhouse ? 'bg-peach-800' : 'bg-primary-chocolate'"
>
  <div class="take-your-place-content">
    <div class="logo-header">
      <img
        (click)="landingRedirect()"
        alt="logo"
        class="logo"
        [src]="logoUrl"
      />
    </div>

    <app-general-card>
      <div class="application-complete-card">
        <app-sign-up-form-stepper
          [platformName]="platformName"
          [pageNumber]="3"
        ></app-sign-up-form-stepper>

        <div class="application-information">
          <h2 class="auth-form-title">We have received your application</h2>
          <br />
          <p class="text-tags-tag_red">
            You will be notified shortly by email of the outcome
          </p>
          <br />
          <p class="text-tags-tag_red font-bold">
            Please note you will not be able to access
            {{ isBoardhouse ? platformName : "the " + platformName }} until you
            are approved
          </p>
          <br />
          <button
            (click)="gotoAboutPage()"
            class="self-center"
            [ngClass]="
              isBoardhouse ? 'dark-outlined-button' : 'outlined-button'
            "
            id="take_your_place_complete"
          >
            You may now exit this page
          </button>
        </div>
      </div>
    </app-general-card>
  </div>
</div>
