import { CanActivateChildFn } from "@angular/router";
import { Team350Store } from "@shared/store/action-trackers/services/team-350.store";
import { inject } from "@angular/core";
import { getMyAuthInfo } from "src/app/modules/auth/store/auth.actions";
import { catchError, of, switchMap } from "rxjs";
import { select } from "@ngrx/store";
import { selectAuthInitialised } from "src/app/modules/auth/store/auth.selectors";

// A guard to ensure auth user is set
export const getAuthUserGuard: CanActivateChildFn = (route, state) => {
  const store: Team350Store = inject(Team350Store);

  return store.pipe(
    select(selectAuthInitialised),
    switchMap((authInitialised) => {
      if (authInitialised) {
        return of(true);
      }
      const actionTracker = store.trackDispatch(getMyAuthInfo());
      // Regardless of request is successful or not, return true
      return actionTracker.onComplete().pipe(
        catchError((err) => of(true)),
        switchMap((result) => of(true)),
      );
    }),
  );
};
