//////////////////////////////////////////////////////////////////
// Pagination
//////////////////////////////////////////////////////////////////

import { config } from "@config";

export interface PaginationEntityState {
  page: number; // Current page index
  pageSize: number; // The number of items per page
  pageCount: number | null; // The total number of pages
  total: number | null; // Total number of items
  noPaginate?: boolean; // Turn off pagination and return full result
}

export const defaultPaginationState: PaginationEntityState = {
  page: config.pagination.DEFAULT_PAGE,
  pageCount: null,
  pageSize: config.pagination.DEFAULT_PAGE_SIZE,
  total: null,
  noPaginate: false,
};
