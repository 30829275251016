import { AbstractControl, FormGroup } from "@angular/forms";
import { isEmpty, isNil } from "lodash";

export const isFtseCompanyBooleanFormControlName: string = "isFTSECompany";
export const ftseCompanyFormControlName: string = "company";
export const nonFtseCompanyFormControlName: string = "nonFTSECompanyName";

/**
 * This will add the 'required' validator to the corresponding form control
 * depending on whether the form control has been set to true or false.
 * @param ftseCompanyControlName The form control name of the FTSE company's
 * control
 * @param nonFtseCompanyControlName The form control name of the Non FTSE
 * company's control
 * @param ftseBooleanIndicatorControlName The form control name of the boolean
 * used to determine if the company is an FTSE company or not
 */
export function ftseCompanyValidator(
  ftseCompanyControlName: string,
  nonFtseCompanyControlName: string,
  ftseBooleanIndicatorControlName: string,
) {
  return (formGroup: FormGroup) => {
    const ftseControl: AbstractControl =
      formGroup.controls[ftseCompanyControlName];
    const nonFtseControl: AbstractControl =
      formGroup.controls[nonFtseCompanyControlName];
    const booleanFtseControl: AbstractControl =
      formGroup.controls[ftseBooleanIndicatorControlName];

    if (isNil(booleanFtseControl)) {
      // Could not find boolean indicator for validation checks
      return;
    }

    if (booleanFtseControl.value === true) {
      if (isNil(ftseControl)) {
        // Could not find FTSE control for validation checks
        return;
      }
      setControlAsRequired(ftseControl, nonFtseControl);
    } else if (booleanFtseControl.value === false) {
      if (isNil(nonFtseControl)) {
        // Could not find non FTSE control for validation checks
        return;
      }
      // non ftseCompanyControlName control must be required
      setControlAsRequired(nonFtseControl, ftseControl);
    } else {
      clearFormControlErrors(nonFtseControl);
      clearFormControlErrors(ftseControl);
    }
  };
}

/**
 * This function is used to determine whether the required form control has been
 * filled in appropriately and set the corresponding errors. This function will
 * clear the errors of both form controls when needed.
 * @param requiredFormControl the form control to add 'required' validation
 * @param otherFormControl the form control that needs its errors to be cleared
 */
function setControlAsRequired(
  requiredFormControl: AbstractControl,
  otherFormControl: AbstractControl,
) {
  if (
    isEmpty(requiredFormControl.value) // also works for undefined, null and empty strings
  ) {
    requiredFormControl.setErrors({ required: true });
  } else {
    clearFormControlErrors(requiredFormControl);
    clearFormControlErrors(otherFormControl);
  }
}

/**
 * This function checks if the provided form control is null or not before
 * removing the errors from it.
 * @param formControl the form control to remove the errors from
 */
function clearFormControlErrors(formControl: AbstractControl) {
  if (!isNil(formControl)) {
    formControl.setErrors(null);
  }
}
