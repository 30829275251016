import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import {
  BehaviorSubject,
  catchError,
  delay,
  filter,
  Observable,
  Subject,
  switchMap,
  take,
  timer,
} from "rxjs";
import {
  logoutUnauthorised,
  refreshToken,
} from "src/app/modules/auth/store/auth.actions";
import { Team350Store } from "@shared/store/action-trackers/services/team-350.store";
import { backendRoutes } from "@shared/config/backend-routes";
import { isNil } from "lodash";

const ignoreUnauthorised = [
  backendRoutes.loginEndpoint,
  backendRoutes.refreshEndpoint,
  backendRoutes.otpEndpoint,
  backendRoutes.logoutUnauthorised,
];

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  private isRefreshingToken: boolean = false;
  private refreshTokenReady$: Subject<any> = new BehaviorSubject<any>(null);

  constructor(private store: Team350Store) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    // If in ignore unauthorised request, handle as normal
    const skipRefresh: boolean = ignoreUnauthorised.some((substring) =>
      request.url.includes(substring),
    );

    if (skipRefresh) {
      return this.handleRequest(request, next, false, skipRefresh);
    }

    if (this.isRefreshingToken) {
      // If currently refreshing token, wait for refresh to finish before handling request
      return this.refreshTokenCheck(request, next);
    }

    // Otherwise, handle with an attempt to refresh
    return this.handleRequest(request, next, true, false);
  }

  refreshTokenCheck(request: HttpRequest<unknown>, next: HttpHandler) {
    return this.refreshTokenReady$.pipe(
      filter((value) => isNil(value)),
      delay(1000),
      take(1),
      switchMap((value) => {
        return this.handleRequest(request, next, false, false);
      }),
    );
  }

  private handleRequest(
    req: HttpRequest<any>,
    next: HttpHandler,
    shouldHandleRefresh: boolean = true,
    skipRefresh: boolean = true,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      // If an error comes through, check if unauthorised and should refresh
      catchError((error: HttpErrorResponse) => {
        if (
          error.status === 401 &&
          shouldHandleRefresh &&
          !this.isRefreshingToken
        ) {
          this.isRefreshingToken = true;
          // If so, handle unauthorized
          return this.handleUnauthorized(req, next, error);
        }
        if (skipRefresh || error.status !== 401) {
          throw error;
        }
        return this.refreshTokenCheck(req, next);
      }),
    );
  }

  private handleUnauthorized(
    request: HttpRequest<any>,
    next: HttpHandler,
    error: HttpErrorResponse,
  ): Observable<HttpEvent<unknown>> {
    // Would only get here if not currently refreshing token
    // On receiving unauthorised, set local variables to indicate refreshing token
    this.refreshTokenReady$.next(null);

    // Refresh token
    const actionTracker = this.store.trackDispatch(refreshToken());
    return actionTracker.onComplete().pipe(
      // If there is an error on refreshing token, log user out
      catchError((refreshError) => {
        this.store.dispatch(logoutUnauthorised());
        throw error;
      }),
      switchMap((result) => {
        this.refreshTokenReady$.next(true);
        this.isRefreshingToken = false;
        // If successful, try to handle request again with new access tokens added via refresh token flow
        // NB: note the false in the handlerequest function here
        // Timer might not be necessary
        return timer(50).pipe(
          switchMap(() => this.handleRequest(request, next, false, true)),
        );
      }),
    );
  }
}
