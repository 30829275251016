import { createReducer, on } from "@ngrx/store";
import { initialProfileState } from "./profile.state";
import {
  editUserProfilePictureSuccess,
  getProfileForUser,
  getProfileForUserFailure,
  getProfileForUserSuccess,
  getUserProfile,
  getUserProfileFailure,
  getUserProfileSuccess,
  updateUserAboutDetails,
  updateUserAboutDetailsFailure,
  updateUserAboutDetailsSuccess,
  updateUserDetails,
  updateUserDetailsFailure,
  updateUserDetailsSuccess,
  updateUserPreferencesSuccess,
} from "./profile.action";
import {
  loadingState,
  loadingStateFailure,
  loadingStateSuccess,
} from "@shared/helpers/loading-state";

export const profileReducer = createReducer(
  initialProfileState,
  on(getUserProfile, (state) => ({
    ...state,
    getUserProfile: loadingState(true),
  })),
  on(getUserProfileSuccess, (state, payload) => ({
    ...state,
    getUserProfile: loadingStateSuccess(),
    userProfile: payload.userProfile,
  })),
  on(getUserProfileFailure, (state, payload) => ({
    ...state,
    getUserProfile: loadingStateFailure(payload.message, payload.error),
  })),
  on(updateUserDetails, (state) => ({
    ...state,
    editUserDetails: loadingState(true),
  })),
  on(updateUserDetailsSuccess, (state, payload) => ({
    ...state,
    editUserDetails: loadingStateSuccess(),
    userProfile: payload.userProfile,
  })),
  on(updateUserDetailsFailure, (state, payload) => ({
    ...state,
    editUserDetails: loadingStateFailure(payload.message, payload.error),
  })),
  on(updateUserAboutDetails, (state) => ({
    ...state,
    editAboutDetails: loadingState(true),
  })),
  on(updateUserAboutDetailsSuccess, (state, payload) => ({
    ...state,
    editAboutDetails: loadingStateSuccess(),
    userProfile: payload.userProfile,
  })),
  on(updateUserAboutDetailsFailure, (state, payload) => ({
    ...state,
    editAboutDetails: loadingStateFailure(payload.message, payload.error),
  })),
  on(getProfileForUser, (state) => ({
    ...state,
    getProfileForUser: loadingState(true),
  })),
  on(getProfileForUserSuccess, (state, payload) => ({
    ...state,
    getProfileForUser: loadingStateSuccess(),
  })),
  on(getProfileForUserFailure, (state, payload) => ({
    ...state,
    getProfileForUser: loadingStateFailure(payload.message, payload.error),
  })),

  on(updateUserPreferencesSuccess, (state, payload) => ({
    ...state,
    userProfile: payload.userProfile,
  })),

  on(editUserProfilePictureSuccess, (state, payload) => ({
    ...state,
    userProfile: payload.userProfile,
  })),
);
