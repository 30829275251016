import { createSelector } from "@ngrx/store";
import { FooterState } from "./footer.state";
import { selectFooterState } from "./footer.feature";

export const selectIsGettingFooterItems = createSelector(
  selectFooterState,
  (state: FooterState) => state.getFooterItems.loading,
);

export const selectIsGettingFooterItemsSuccess = createSelector(
  selectFooterState,
  (state: FooterState) => state.getFooterItems.success,
);

export const selectIsGettingFooterItemsFailure = createSelector(
  selectFooterState,
  (state: FooterState) => state.getFooterItems.error,
);
export const selectFooterItems = createSelector(
  selectFooterState,
  (state: FooterState) => state?.footerItems,
);
