import { nanoid } from "nanoid";

export interface ActionTracker {
  trackingId: string;
  loading: boolean;
  success: boolean | undefined;
  message: string;
  action: object;
  data?: object;
}

export class ActionTrackerHelper {
  static create(action: any): ActionTracker {
    return {
      trackingId: nanoid(),
      loading: true,
      success: undefined,
      message: "",
      action,
    };
  }
}
