export enum ViewProfileTabs {
  About = "About",
  Expertise = "Expertise",
  Interests = "Interests",
  SectorExperience = "Sector Experience",
  Groups = "Groups",
}

export enum GroupsTabs {
  Members = "Members",
  EventRooms = "Event Rooms",
  Sessions = "Sessions", // Replaces event rooms for course groups.
  Discussions = "Discussions",
  Resources = "Resources",
  Vacancies = "Vacancies",
}

export const tabs = {
  viewProfileTabsDictionary: {
    0: ViewProfileTabs.About,
    1: ViewProfileTabs.Expertise,
    2: ViewProfileTabs.Interests,
    3: ViewProfileTabs.SectorExperience,
    4: ViewProfileTabs.Groups,
  },
  viewProfileTabs: {
    [ViewProfileTabs.About]: 0,
    [ViewProfileTabs.Expertise]: 1,
    [ViewProfileTabs.Interests]: 2,
    [ViewProfileTabs.SectorExperience]: 3,
    [ViewProfileTabs.Groups]: 4,
  },

  editProfileTabsDictionary: {
    0: "User details",
    1: "About",
    2: "Preferences",
    3: "Security",
  },

  editProfileTabs: {
    UserDetails: 0,
    About: 1,
    Preferences: 2,
    Security: 3,
  },

  groupsTabsDictionary: {
    0: GroupsTabs.Members,
    1: GroupsTabs.EventRooms,
    2: GroupsTabs.Discussions,
    3: GroupsTabs.Resources,
    4: GroupsTabs.Vacancies,
  },
  groupsProfileTabs: {
    [GroupsTabs.Members]: 0,
    [GroupsTabs.EventRooms]: 1,
    [GroupsTabs.Sessions]: 1, // Replaces EventRooms for course groups.
    [GroupsTabs.Discussions]: 2,
    [GroupsTabs.Resources]: 3,
    [GroupsTabs.Vacancies]: 4,
  },

  roomsTabsDictionary: {
    0: "Live & Upcoming events",
    1: "Past events",
    2: "My events",
  },
  roomsProfileTabs: {
    LiveAndUpcomingEvents: 0,
    PastEvents: 1,
    MyEvents: 2,
  },
  courseTabsDictionary: {
    0: "Upcoming courses",
    1: "Past courses",
    2: "My courses",
  },
  courseTabs: {
    UpcomingCourses: 0,
    PastCourses: 1,
    MyCourses: 2,
  },
};

// TODO: Replace tab values with enum
export enum roomTabEnum {
  liveAndUpcoming = "Live & Upcoming events",
  pastEvents = "Past events",
  myEvents = "My events",
}
