import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActionsSubject } from "@ngrx/store";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { config } from "@config";
import {
  resetPassword,
  resetPasswordFailure,
  resetPasswordSuccess,
} from "../store/auth.actions";
import { Subject, takeUntil } from "rxjs";
import { ofType } from "@ngrx/effects";
import { Team350Store } from "@shared/store/action-trackers/services/team-350.store";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  resetPasswordForm!: FormGroup;
  resetPasswordError: string = "";
  isResetSuccessful: boolean = false;

  private jwtToken: string;

  private destroy$ = new Subject<boolean>();

  constructor(
    private store: Team350Store,
    private actionsSubject: ActionsSubject,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.resetPasswordForm = new FormGroup({
      password: new FormControl(null, [
        Validators.required,
        Validators.pattern(config.regex.strongPassword),
      ]),
      confirmPassword: new FormControl(null, [
        Validators.required,
        this.confirmationValidator,
      ]),
    });

    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        this.jwtToken = params["verifyToken"];
        if (!this.jwtToken) {
          this.resetPasswordError = "Missing verify token";
        }
      });
  }

  onResetPassword() {
    const passwordInput: string = this.resetPasswordForm.get("password")?.value;

    this.store.trackDispatch(
      resetPassword({ password: passwordInput, token: this.jwtToken }),
    );

    this.actionsSubject
      .pipe(ofType(resetPasswordSuccess), takeUntil(this.destroy$))
      .subscribe(() => {
        this.isResetSuccessful = true;
        setTimeout(() => {
          this.router.navigate(["/auth/login"]);
        }, 2000);
      });

    this.actionsSubject
      .pipe(ofType(resetPasswordFailure), takeUntil(this.destroy$))
      .subscribe((error) => {
        this.resetPasswordError =
          error?.error?.payload?.message || "Could not complete password reset";
        this.isResetSuccessful = false;
      });
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (
      control.value !== this.resetPasswordForm.controls["password"].value
    ) {
      return { confirm: true, error: true };
    }
    return {};
  };

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
