import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { SelectOptionsClientApiService } from "@shared/api-client";
import {
  getExpertise,
  getExpertiseSuccess,
  getInterests,
  getInterestsSuccess,
  getSectorExperience,
  getSectorExperienceSuccess,
} from "./select-options.actions";
import { catchError, of, switchMap } from "rxjs";
import {
  completeActionTracker,
  failActionTracker,
} from "@shared/store/action-trackers/ngrx/action-tracker.actions";

@Injectable()
export class SelectFilterOptionsEffects {
  getExpertiseSelectOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getExpertise),
      switchMap((action) =>
        this.selectOptionsApiService.selectOptionsControllerGetExpertise().pipe(
          switchMap((response) => {
            return [
              getExpertiseSuccess({
                data: response.data,
                message: response.message,
              }),
              completeActionTracker({ action, data: response }),
            ];
          }),
          catchError((error) => {
            return of(
              failActionTracker({
                action,
                data: { error, message: error?.error.message },
              }),
            );
          }),
        ),
      ),
    ),
  );

  getInterestSelectOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getInterests),
      switchMap((action) =>
        this.selectOptionsApiService.selectOptionsControllerGetInterests().pipe(
          switchMap((response) => {
            return [
              getInterestsSuccess({
                data: response.data,
                message: response.message,
              }),
              completeActionTracker({ action, data: response }),
            ];
          }),
          catchError((error) => {
            return of(
              failActionTracker({
                action,
                data: { error, message: error?.error.message },
              }),
            );
          }),
        ),
      ),
    ),
  );

  getSectorExperienceSelectOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSectorExperience),
      switchMap((action) =>
        this.selectOptionsApiService
          .selectOptionsControllerGetSectorExperience()
          .pipe(
            switchMap((response) => {
              return [
                getSectorExperienceSuccess({
                  data: response.data,
                  message: response.message,
                }),
                completeActionTracker({ action, data: response }),
              ];
            }),
            catchError((error) => {
              return of(
                failActionTracker({
                  action,
                  data: { error, message: error?.error.message },
                }),
              );
            }),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private selectOptionsApiService: SelectOptionsClientApiService,
  ) {}
}
