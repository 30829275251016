import { createAction, props } from "@ngrx/store";
import { ActionTracker } from "@shared/store/action-trackers/action-tracker.model";
import { StandardResponse } from "@shared/dtos/result.dto";

export const addActionTracker = createAction(
  "[Action Tracker] Add action tracker",
  props<{ actionTracker: ActionTracker }>(),
);

export const completeActionTracker = createAction(
  "[Action Tracker] Complete action tracker",
  props<{ action: any; data: any & StandardResponse }>(),
);

export const failActionTracker = createAction(
  "[Action Tracker] Fail Action Tracker",
  props<{ action: any; data: any }>(),
);

export const deleteActionTracker = createAction(
  "[Action Tracker] Delete Action Tracker",
  props<{ id: string }>(),
);

export const clearActionTracker = createAction(
  "[Action Tracker] Clear Action Tracker",
);
