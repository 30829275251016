import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { config } from "@config";

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (request.url.includes(config.backendRoutes.refreshEndpoint)) {
      const tokenClone = request.clone({
        headers: request.headers.set(
          "authorization",
          "Bearer " + request.body.token,
        ),
      });
      return next.handle(tokenClone);
    }
    return next.handle(request);
  }
}
