import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[digitOnly]",
})
export class DigitOnlyDirective {
  private navigationKeys = [
    "Backspace",
    "Delete",
    "Tab",
    "Enter",
    "ArrowLeft",
    "ArrowRight",
  ];

  constructor() {}

  @HostListener("keydown", ["$event"])
  onKeyDown(e: KeyboardEvent) {
    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter, etc
      this.navigationKeys.indexOf(e.key) > -1
    ) {
      return;
    }
    if (e.key === " " || isNaN(Number(e.key))) {
      e.preventDefault();
    }
  }
}
