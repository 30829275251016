import { Injectable } from "@angular/core";
import { Action, select, Store } from "@ngrx/store";
import {
  ActionTracker,
  ActionTrackerHelper,
} from "@shared/store/action-trackers/action-tracker.model";
import {
  addActionTracker,
  deleteActionTracker,
} from "@shared/store/action-trackers/ngrx/action-tracker.actions";
import { filter, map, mergeMap, Observable, take } from "rxjs";
import {
  isLoadingById,
  isSuccessfulById,
  selectActionTrackerById,
} from "@shared/store/action-trackers/ngrx/action-tracker.selectors";
import { isNil } from "lodash";

export interface TrackingHelpers {
  updates$: Observable<ActionTracker | undefined>;
  success$: Observable<boolean | undefined>;
  isLoading$: Observable<boolean | undefined>;
  remove: () => void;
  onSuccess: () => Observable<ActionTracker | undefined>;
  onFailure: () => Observable<ActionTracker | undefined>;
  onComplete: () => Observable<ActionTracker>;
}

@Injectable({ providedIn: "root" })
export class Team350Store extends Store {
  trackDispatch<V extends Action = Action>(action: any) {
    const actionTracker = ActionTrackerHelper.create(action);

    //Giving action the same ID that the tracker has
    action.trackingId = actionTracker.trackingId;

    //Dispatching action
    this.dispatch(action);

    //Dispatch tracking action
    this.dispatch(addActionTracker({ actionTracker }));

    //Get helpers
    return this.getTrackingHelpers(actionTracker, this);
  }

  getTrackingHelpers(
    actionTracker: ActionTracker,
    store: Store,
  ): TrackingHelpers {
    return {
      updates$: store.pipe(
        select(selectActionTrackerById(actionTracker.trackingId)),
      ),
      success$: store.pipe(select(isSuccessfulById(actionTracker.trackingId))),
      isLoading$: store.pipe(select(isLoadingById(actionTracker.trackingId))),
      remove: () =>
        store.dispatch(deleteActionTracker({ id: actionTracker.trackingId })),
      onSuccess: () =>
        store.pipe(
          select(isSuccessfulById(actionTracker.trackingId)),
          filter((v) => v === true),
          take(1),
          mergeMap(() =>
            this.pipe(
              select(selectActionTrackerById(actionTracker.trackingId)),
            ),
          ),
        ),
      onFailure: () =>
        store.pipe(
          select(isSuccessfulById(actionTracker.trackingId)),
          filter((v) => v === false),
          take(1),
          mergeMap(() =>
            this.pipe(
              select(selectActionTrackerById(actionTracker.trackingId)),
            ),
          ),
        ),
      onComplete: () =>
        store.pipe(
          select(selectActionTrackerById(actionTracker.trackingId)),
          filter((v) => !isNil(v?.success)),
          take(1),
          map((action) => {
            if (action?.success === true) {
              return action;
            } else {
              throw action;
            }
          }),
        ),
    };
  }
}
