import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Team350Store } from "@shared/store/action-trackers/services/team-350.store";
import { Subject, takeUntil } from "rxjs";
import { selectAuthenticated } from "@auth/store/auth.selectors";

@Component({
  selector: "logged-out-header",
  templateUrl: "./logged-out-header.component.html",
  styleUrls: ["./logged-out-header.component.scss"],
})
export class LoggedOutHeaderComponent implements OnInit, OnDestroy {
  isAuthenticated: boolean = false;
  @Input() platformLogoFileName: string;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private store: Team350Store,
  ) {}

  ngOnInit() {
    this.store
      .select(selectAuthenticated)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (isAuthenticatedUser: boolean): void => {
          this.isAuthenticated = isAuthenticatedUser;
        },
      });
  }

  landingRedirect(): void {
    this.router.navigate(["/landing"]);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
