//////////////////////////////////////////////////////////////////
// Query and search
//////////////////////////////////////////////////////////////////

import { SortOrder } from "@shared/enums/query-helper/sort-order.enum";
import { QueryOperator } from "@shared/enums/query-helper/query-operator.enum";
import { QueryFieldType } from "@shared/enums/query-helper/query-field.enum";

export interface QueryField {
  type: QueryFieldType;
  field: string;
  operator: QueryOperator;
  value: string | boolean | string[];
  label?: string;
}

export interface QueryFilter {
  name: string;
  args?: { [key: string]: any };
  isBaseFilter?: boolean;
}

export interface SortField {
  field: string;
  order: SortOrder;
}

export interface SearchField {
  field: string;
  searchString: string;
}

export interface QueryEntityState {
  defaultSearchString: string;
  searching: SearchField[];
  fields: QueryField[];
  hiddenFields: QueryField[]; // These are fields which cannot be adjusted but which will exist in all queries
  sorting: SortField[];
  filters: QueryFilter[];
}

export const defaultQueryState: QueryEntityState = {
  defaultSearchString: "",
  searching: [],
  fields: [],
  hiddenFields: [],
  sorting: [],
  filters: [],
};
