import { createSelector } from "@ngrx/store";
import { selectProfileState } from "./profile.feature";
import { ProfileState } from "./profile.state";

export const selectGetUserProfile = createSelector(
  selectProfileState,
  (state: ProfileState) => state.getUserProfile.loading,
);

export const selectGetUserProfileSuccess = createSelector(
  selectProfileState,
  (state: ProfileState) => state.getUserProfile.success,
);

export const selectGetUserProfileFailure = createSelector(
  selectProfileState,
  (state: ProfileState) => state.getUserProfile.error,
);

export const selectUserProfile = createSelector(
  selectProfileState,
  (state: ProfileState) => state.userProfile,
);

export const selectIsAuthUserMentor = createSelector(
  selectProfileState,
  (state: ProfileState) => state.userProfile.isMentor,
);

export const selectIsAuthUserExecutive = createSelector(
  selectProfileState,
  (state: ProfileState) =>
    state.userProfile.details.professionalFlags.executive,
);
