export const signUpDiversity = {
  EthnicallyDiverse: "Ethnically diverse",
  Neurodiverse: "Neurodiverse",
  SociallyMobile: "Socially diverse",
  PhysicallyDisabled: "Physically disabled / long-term health condition",
};
export const profileDiversity = {
  EthnicallyDiverse: "Ethnically diverse",
  Neurodiverse: "Neurodiverse",
  SociallyMobile: "Socially diverse",
  PhysicallyDisabled: "Physically disabled / long-term health condition",
  PreferNotToSay: "Prefer not to say",
};
