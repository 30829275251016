import { ActionCreator, on, ReducerTypes } from "@ngrx/store";
import { ChatState } from "./chat.ngrx";
import {
  getUnreadChatIdsSuccess,
  removeChatIdFromUnreadList,
  setUnreadChatId,
} from "./chat.actions";
import { uniq } from "lodash";

export const customChatReducers: ReducerTypes<ChatState, ActionCreator[]>[] = [
  on(setUnreadChatId, (state, action) => {
    const unreadChatIds = uniq([...state.unreadChatIds, action.chatId]);
    return {
      ...state,
      unreadChatIds: unreadChatIds,
    };
  }),

  on(removeChatIdFromUnreadList, (state, action) => {
    const unreadChatIds = state.unreadChatIds.filter(
      (id) => id !== action.chatId,
    );
    return {
      ...state,
      unreadChatIds: unreadChatIds,
    };
  }),

  on(getUnreadChatIdsSuccess, (state, action) => {
    return {
      ...state,
      unreadChatIds: action.chatIds,
    };
  }),
];
