import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { selectOptionsFeature } from "./store/select-options.feature";
import { EffectsModule } from "@ngrx/effects";
import { SelectOptionsEffects } from "./store/select-options.effects";
import { SelectFilterOptionsEffects } from "./store/select-filter-options.effects";

@NgModule({
  imports: [
    StoreModule.forFeature(selectOptionsFeature),
    EffectsModule.forFeature([
      SelectOptionsEffects,
      SelectFilterOptionsEffects,
    ]),
  ],
})
export class SelectOptionsModule {}
