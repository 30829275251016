import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActionsSubject } from "@ngrx/store";
import {
  login,
  loginFailure,
  loginSuccess,
  switchPlatform,
  verifyUser,
} from "../../store/auth.actions";
import { regex } from "@config";
import { Subject, takeUntil } from "rxjs";
import { ofType } from "@ngrx/effects";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "@environments/environment";
import { ReCaptcha2Component } from "ngx-captcha";
import {
  Team350Store,
  TrackingHelpers,
} from "@shared/store/action-trackers/services/team-350.store";
import { isNil } from "lodash";

@Component({
  selector: "app-log-in",
  templateUrl: "./log-in.component.html",
  styleUrls: ["./log-in.component.scss"],
})
export class LogInComponent implements OnInit {
  recaptchaKey: string = environment.siteKey;
  environmentType: boolean = environment.production;
  firstSignIn: boolean = false;
  // @ts-ignore
  loginAction: TrackingHelpers;

  @ViewChild("reCaptcha2Component")
  // @ts-ignore
  private reCaptcha2Component: ReCaptcha2Component;

  loginForm: FormGroup = new FormGroup({
    email: new FormControl("", [
      Validators.required,
      Validators.pattern(regex.email),
    ]),
    password: new FormControl("", [Validators.required]),
    recaptchaToken: new FormControl(
      "",
      this.environmentType ? [Validators.required] : [],
    ),
  });
  loginFailed: boolean = false;
  redirectUrl: string = "";
  destroyed$ = new Subject<boolean>();
  verifyToken: string;
  redirectPlatform: any;

  constructor(
    private store: Team350Store,
    private actionsSubject: ActionsSubject,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  landingRedirect() {
    this.router.navigate(["/landing"]);
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.destroyed$))
      .subscribe((params) => {
        this.firstSignIn = params["review"];
        this.redirectUrl = params["redirectTo"];
        this.verifyToken = params["verifyToken"];
        this.redirectPlatform = params["platform"];
        if (!isNil(this.verifyToken)) {
          this.verifyUser(this.verifyToken);
        }
      });

    this.actionsSubject
      .pipe(ofType(loginFailure), takeUntil(this.destroyed$))
      .subscribe(() => {
        this.loginFailed = true;
        if (this.environmentType) {
          this.reCaptcha2Component.resetCaptcha();
        }
      });
    this.actionsSubject
      .pipe(ofType(loginSuccess), takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.redirectPlatform) {
          this.store.trackDispatch(
            switchPlatform({ platformName: this.redirectPlatform }),
          );
        }

        if (this.redirectUrl) {
          this.router.navigate([this.redirectUrl]);
        } else if (this.firstSignIn) {
          this.router.navigate(["/home/profile/edit"]);
        } else {
          this.router.navigate(["/home"]);
        }
      });
  }

  verifyUser(verificationToken: string) {
    this.store.trackDispatch(verifyUser({ verificationToken }));
  }

  logIn() {
    if (!this.loginForm.valid) {
      return;
    }
    const formValues = this.loginForm.value;

    this.loginAction = this.store.trackDispatch(
      login({
        email: formValues.email,
        password: formValues.password,
        recaptchaToken: formValues.recaptchaToken,
      }),
    );
  }
}
