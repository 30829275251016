import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { getFooterItemsReq, getFooterItemsSuccess } from "./footer.actions";
import { FooterClientApiService } from "@shared/api-client";
import { catchError, of, switchMap } from "rxjs";
import {
  completeActionTracker,
  failActionTracker,
} from "@shared/store/action-trackers/ngrx/action-tracker.actions";

@Injectable()
export class FooterEffects {
  getFooterItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFooterItemsReq),
      switchMap((action) =>
        this.footerClientApiService
          .footerControllerGetFooterItems({ platform: action.platform })
          .pipe(
            switchMap((response) => {
              return [
                getFooterItemsSuccess({
                  footerItems: response.data,
                }),
                completeActionTracker({ action, data: response }),
              ];
            }),
            catchError((error) => {
              return of(
                failActionTracker({
                  action,
                  data: { error, message: error?.message },
                }),
              );
            }),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private readonly footerClientApiService: FooterClientApiService,
  ) {}
}
