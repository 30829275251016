import { AbstractControl } from "@angular/forms";

export function getErrorMessage(
  formControl: AbstractControl,
  inputDescription: string,
  shouldValidate = false,
  showDefaultMessage: boolean = true,
) {
  if (
    (formControl?.touched || formControl?.dirty || shouldValidate) &&
    formControl?.errors
  ) {
    if (formControl?.errors["required"]) {
      const message: string = `${inputDescription}`;
      return showDefaultMessage ? message + " is required" : message;
    } else if (formControl?.invalid && formControl?.errors["custom"]) {
      return formControl.errors["custom"];
    } else if (
      formControl?.invalid &&
      inputDescription === "Password" &&
      formControl?.value != ""
    ) {
      return `${inputDescription} requires one uppercase, one digit, one special character (!£$%&*()_+@#?<>=-) and be at least 8 characters`;
    } else if (
      formControl?.invalid &&
      inputDescription === "Confirm Password" &&
      formControl?.value != ""
    ) {
      return `${inputDescription} must match password`;
    } else if (formControl?.errors) {
      const message: string = `${inputDescription}`;
      return showDefaultMessage ? message + " is invalid" : message;
    }
  }

  return "";
}
