import { createAction, props } from "@ngrx/store";
import { StandardError } from "@shared/models/standard-error.model";
import { UserLoginResponseDto } from "../models/responses/user-login-response.dto";
import { SignupModel } from "../models/requests/signup.model";
import { UserSignupResponseDto } from "../models/responses/user-signup-response.dto";
import { SwitchPlatformDto } from "@shared/api-client";

export const login = createAction(
  "[Auth] Login",
  props<{
    email: string;
    password: string;
    redirectPath?: string[];
    recaptchaToken: string;
  }>(),
);
export const loginSuccess = createAction(
  "[Auth] Login Success",
  props<UserLoginResponseDto>(),
);
export const loginFailure = createAction(
  "[Auth] Login Failure",
  props<StandardError>(),
);

export const signup = createAction(
  "[Auth] Signup",
  props<{ signupDto: SignupModel }>(),
);
export const signupSuccess = createAction(
  "[Auth] Signup Success",
  props<UserSignupResponseDto>(),
);
export const signupFailure = createAction(
  "[Auth] Signup Failure",
  props<StandardError>(),
);

export const refreshToken = createAction("[Auth] Refresh Token");
export const refreshTokenSuccess = createAction(
  "[Auth] Refresh Token Success",
  props<{
    accessExpiresAt: string;
  }>(),
);
export const refreshTokenFailure = createAction(
  "[Auth] Refresh Token Failure",
  props<StandardError>(),
);

export const logout = createAction("[Auth] Logout");
export const logoutUnauthorised = createAction("[Auth] Logout Unauthorised");
export const logoutSuccess = createAction("[Auth] Logout Success");
export const logoutFailure = createAction(
  "[Auth] Logout Failure",
  props<StandardError>(),
);

export const requestForgotPasswordLink = createAction(
  "[Auth] Request Reset Password Link",
  props<{ email: string; recaptchaToken: string }>(),
);

export const resetPassword = createAction(
  "[Auth] Password Reset",
  props<{ token: string; password: string }>(),
);
export const resetPasswordSuccess = createAction(
  "[Auth] Password Reset Success",
);
export const resetPasswordFailure = createAction(
  "[Auth] Password Reset Failure",
  props<StandardError>(),
);

export const getMyAuthInfo = createAction("[Auth] Get My Auth Info");
export const getMyAuthInfoSuccess = createAction(
  "[Auth] Get My Auth Info Success",
  props<UserLoginResponseDto>(),
);
export const verifyUser = createAction(
  "[Auth] Verify User",
  props<{ verificationToken: string }>(),
);
export const verifyUserSuccess = createAction("[Auth] Verify User Success");

export const switchPlatform = createAction(
  "[Auth] Switch Platform",
  props<SwitchPlatformDto>(),
);
export const switchPlatformSuccess = createAction(
  "[Auth] Switch Platform Success",
  props<UserLoginResponseDto>(),
);
export const switchPlatformFailure = createAction(
  "[Auth] Switch Platform Failure",
  props<StandardError>(),
);
