import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { authGuard } from "@auth/guards/auth.guard";
import { loggedOutGuard } from "@auth/guards/logged-out.guard";
import { refreshGuard } from "@auth/guards/refresh.guard";
import { getAuthUserGuard } from "src/app/modules/auth/guards/get-auth-user.guard";

const routes: Routes = [
  {
    path: "",
    canActivate: [getAuthUserGuard],
    children: [
      {
        path: "landing",
        loadChildren: () =>
          import("./modules/landing-page/landing-page.module").then(
            (m) => m.LandingPageModule,
          ),
        canActivate: [loggedOutGuard],
      },
      {
        path: "home",
        loadChildren: () =>
          import("./modules/member-root/member-root.module").then(
            (m) => m.MemberRootModule,
          ),
        canActivate: [authGuard, refreshGuard],
      },
      {
        path: "auth",
        loadChildren: () =>
          import("./modules/auth/auth.module").then((m) => m.AuthModule),
        canActivate: [loggedOutGuard],
      },
      {
        path: "otp",
        loadChildren: () =>
          import("./modules/otp/otp.module").then((m) => m.OtpModule),
        canActivate: [loggedOutGuard],
      },
      {
        path: "info",
        loadChildren: () =>
          import("./modules/information/information.module").then(
            (m) => m.InformationModule,
          ),
      },
      { path: "**", redirectTo: "/landing", pathMatch: "full" },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
