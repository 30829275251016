import { createAction, props } from "@ngrx/store";
import { StandardError } from "@shared/models/standard-error.model";
import { UserProfile } from "../models/user-profile.model";
import { EditUserDetails } from "../models/edit-user-details";
import { EditAboutDetails } from "../models/edit-about-details";
import { Preferences } from "@shared/models/user-details.model";
import { UserPlatformDto } from "../models/user-platform";
import { EditUserProfilePictureDto } from "../models/edit-user-profile-picture.dto";
import { UploadCvDto } from "@shared/api-client";

export const getUserProfile = createAction(
  "[Profile] Get User Profile Request",
);
export const getUserProfileSuccess = createAction(
  "[Profile] Get User Profile Request Success",
  props<{ userProfile: UserProfile }>(),
);

export const getUserProfileFailure = createAction(
  "[Profile] Get User Profile Request Failure",
  props<StandardError>(),
);

export const updateUserDetails = createAction(
  "[Profile] Update User Details",
  props<{ editDto: EditUserDetails }>(),
);

export const updateUserDetailsSuccess = createAction(
  "[Profile] Update User Details Success",
  props<{ userProfile: UserProfile }>(),
);

export const updateUserDetailsFailure = createAction(
  "[Profile] Update User Details Failure",
  props<StandardError>(),
);

export const userResetPassword = createAction(
  "[User] User Reset Password Request",
  props<{ currentPassword: string; newPassword: string }>(),
);

export const userResetPasswordSuccess = createAction(
  "[User] User Reset Password Request Success",
);

export const userResetPasswordFailre = createAction(
  "[User] User Reset Password Request Failre",
  props<StandardError>(),
);

export const updateUserAboutDetails = createAction(
  "[Profile] Update User About Details",
  props<{ editDto: EditAboutDetails }>(),
);

export const updateUserAboutDetailsSuccess = createAction(
  "[Profile] Update User About Details Success",
  props<{ userProfile: UserProfile }>(),
);

export const updateUserAboutDetailsFailure = createAction(
  "[Profile] Update User About Details Failure",
  props<StandardError>(),
);

export const updateUserPreferences = createAction(
  "[Profile] Update User Preferences",
  props<{ preferences: Preferences }>(),
);

export const updateUserPreferencesSuccess = createAction(
  "[Profile] Update User Preferences Success ",
  props<{ userProfile: UserProfile }>(),
);

export const updateUserPreferencesFailure = createAction(
  "[Profile] Update User Preferences Failure",
  props<StandardError>(),
);

export const getProfileForUser = createAction(
  "[Profile] Get Profile For User Request",
  props<{ userDto: UserPlatformDto }>(),
);
export const getProfileForUserSuccess = createAction(
  "[Profile] Get Profile For User Request Success",
  props<{ userProfile: UserProfile }>(),
);

export const getProfileForUserFailure = createAction(
  "[Profile] Get Profile For User Request Failure",
  props<StandardError>(),
);

export const editUserProfilePicture = createAction(
  "[Profile] Edit User Profile Picture",
  props<{ editUserProfilePictureDto: EditUserProfilePictureDto }>(),
);

export const editUserProfilePictureSuccess = createAction(
  "[Profile] Edit User Profile Picture Success ",
  props<{ userProfile: UserProfile }>(),
);

export const uploadUserCv = createAction(
  "[Profile] Upload User CV",
  props<{ uploadCvDto: UploadCvDto }>(),
);
