<div class="auth-page-container">
  <div class="auth-form-container">
    <div class="flex w-3/4 justify-evenly">
      <img
        (click)="landingRedirect()"
        alt="logo"
        class="logo-image"
        src="assets/images/platform-logos/350-club-final-cream.png"
        style="cursor: pointer"
      />
      <img src="assets/images/vertical-line.png" />
      <img
        (click)="landingRedirect()"
        alt="logo"
        class="logo-image left-border"
        src="assets/images/platform-logos/board-house-logo-dark.svg"
        style="cursor: pointer"
      />
    </div>

    <app-general-card [centeredContent]="true">
      <h1 class="auth-form-title">Members sign in</h1>
      <form [formGroup]="loginForm" class="form mt-3 mb-8">
        <div class="form-controls-container">
          <div class="input-container">
            <input
              class="secondary-input"
              formControlName="email"
              placeholder="Your email address"
              type="text"
            />
            <span
              *ngIf="
                loginForm.get('email')?.dirty && loginForm.get('email')?.errors
              "
              class="error-text"
            >
              Your email is invalid
            </span>
          </div>
          <div class="input-container">
            <input
              class="secondary-input"
              formControlName="password"
              placeholder="Password"
              type="password"
            />
          </div>
        </div>
        <div *ngIf="environmentType" class="flex justify-center">
          <ngx-recaptcha2
            #reCaptcha2Component
            [siteKey]="recaptchaKey"
            [useGlobalDomain]="false"
            formControlName="recaptchaToken"
            hl="en"
            size="normal"
            theme="light"
            type="image"
          >
          </ngx-recaptcha2>
        </div>
        <div class="flex h-fit justify-center">
          <button
            (click)="logIn()"
            [disabled]="!this.loginForm.valid"
            class="primary-350-button"
          >
            Login
          </button>
        </div>
        <div *ngIf="loginFailed" class="failure-text-box">
          <span class="error-text"
            >An error has occurred and we could not log you in</span
          >
        </div>
      </form>
      <a class="hyperlink" routerLink="/auth/forgot-password"
        >Forgotten your password?</a
      >
      <a class="hyperlink" routerLink="/auth/take-your-place">
        Apply to join the 350 club</a
      >
      <a class="hyperlink" routerLink="/auth/boardhouse-sign-up">
        Apply to join the Boardhouse</a
      >
    </app-general-card>
  </div>
  <app-general-alert-modal
    [action]="loginAction"
    [onlyShowError]="true"
  ></app-general-alert-modal>
</div>
