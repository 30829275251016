import { Injectable } from "@angular/core";
import { config } from "@config";
import { ChatNgrx } from "src/app/modules/member-root/modules/chat/store/chat.ngrx";
import { filter, firstValueFrom, map } from "rxjs";
import { select } from "@ngrx/store";
import { isNil } from "lodash";
import { MessageNgrx } from "src/app/modules/member-root/modules/message/store/message.ngrx";
import { Team350Store } from "@shared/store/action-trackers/services/team-350.store";
import { Router } from "@angular/router";
import { incrementUnreadMessage } from "src/app/modules/member-root/modules/message/store/message.actions";
import {
  removeChatIdFromUnreadList,
  setUnreadChatId,
} from "../../member-root/modules/chat/store/chat.actions";

@Injectable({
  providedIn: "root",
})
export class RealtimeHandleNewMessageService {
  constructor(
    private store: Team350Store,
    private router: Router,
  ) {}

  onNewMessage = ({
    chatId,
    messageId,
  }: {
    messageId: string;
    chatId: string;
  }) => {
    // TODO: Separate to this function, on new chat?

    // Refresh unread message counter
    this.incrementUnreadMessages();

    // If on chats page, reorder chats list
    this.updateChatList(chatId);

    // If on same chat, get new messages
    this.updateCurrentChat(chatId, messageId);
  };

  // A function to refresh the chat list
  private updateChatList(chatId: string) {
    this.store.dispatch(setUnreadChatId({ chatId: chatId }));

    if (this.router.url === config.frontendRoutes.userMessages) {
      this.store.trackDispatch(
        ChatNgrx.requestActions.findOneReq({ id: chatId }),
      );
    }
  }

  // A function to get new messages for currently selected chat if on that chat
  private updateCurrentChat(chatId: string, messageId: string) {
    firstValueFrom(
      this.store.pipe(
        select(ChatNgrx.selectCurrentlySelectedData()),
        filter((chat) => !isNil(chat?.id)),
        map((chat) => chat.id),
      ),
    ).then((currentChatId) => {
      // Only retrieve message if chat id of payload is same as current chat id
      if (currentChatId === chatId) {
        this.store.dispatch(removeChatIdFromUnreadList({ chatId: chatId }));

        this.store.trackDispatch(
          MessageNgrx.requestActions.findOneReq({ id: messageId }),
        );
      }
    });
  }

  private incrementUnreadMessages() {
    this.store.dispatch(incrementUnreadMessage());
  }
}
