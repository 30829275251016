import { createSelector } from "@ngrx/store";
import * as fromActionTracker from "./action-tracker.reducer";

export const selectActionTrackerById = (id: string) => {
  return createSelector(
    fromActionTracker.selectEntities,
    (entities) => entities[id],
  );
};

export const isLoadingById = (id: string) => {
  return createSelector(
    selectActionTrackerById(id),
    (actionTracker) => actionTracker?.loading,
  );
};

export const isSuccessfulById = (id: string) => {
  return createSelector(
    selectActionTrackerById(id),
    (actionTracker) => actionTracker?.success,
  );
};
