import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import * as fromActionTracker from "./ngrx/action-tracker.reducer";

@NgModule({
  imports: [
    StoreModule.forFeature(
      fromActionTracker.actionTrackerFeatureKey,
      fromActionTracker.reducer,
    ),
  ],
})
export class ActionTrackerModule {}
