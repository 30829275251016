import { Environment } from "@environments/environment.model";

export const environment: Environment = {
  production: true,
  apiBaseUrl: "the350.club",
  siteKey: "6LfwUSQoAAAAALYF0GkSvMgXwMdFJGUDuzbllEwn",
  calendlyClientId: "Vhls9xoEV6cWJQfEQT20K1Tan-g5NS6NM-7OS1861rY",
  calendlyRedirectUri: "https://the350.club/home/profile",
  gtmTag: "GTM-KK4B73VR",
  privacyPolicy: "https://the350.club/privacy-policy",
  hotjarTrackingCode: "3864880",
  hotjarVersion: 6,
  sentryKey:
    "https://8d9590cf07d641fa18176af9f702e834@o4506818032304128.ingest.sentry.io/4506818079752192",
};
