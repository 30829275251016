import {
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { GenericDropdownOptions } from "@shared/models/generic-dropdown-options.model";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DeviceDetectorService } from "ngx-device-detector";
import { isNull } from "lodash";

@Component({
  selector: "app-dropdown-component",
  templateUrl: "./dropdown-component.component.html",
  styleUrls: ["./dropdown-component.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
  ],
})
export class DropdownComponent implements OnInit, ControlValueAccessor {
  constructor(private deviceService: DeviceDetectorService) {}

  showList: boolean = false;
  disabled: boolean = false;
  touched: boolean = false;
  isPrefix: boolean = false;
  isTertiary: boolean = false;
  optionList: GenericDropdownOptions[] = [];
  keyword: string = "";
  isAlreadyAdding: boolean = false;

  @Input() label: string = "";
  @Input() description: string = "";
  private _options: GenericDropdownOptions[] = [];
  @Input() set options(options: GenericDropdownOptions[]) {
    this._options = options;
    this.searchList = options;
  }
  get options(): GenericDropdownOptions[] {
    return this._options;
  }
  @Input() type: string = "";
  @Input() isSingle: boolean = true;
  @Input() placeholder: string = "";
  @Output() selectedItem = new EventEmitter<GenericDropdownOptions[]>();
  @Output() touchedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  isScrolling: boolean = false;
  isMobileOrTablet: boolean = false;

  searchList: GenericDropdownOptions[] = [];

  onChange: any = () => {};
  onTouched: any = () => {};
  touchMoveEventTriggered() {
    this.isScrolling = true;
  }
  touchStarted() {
    this.isScrolling = false;
  }

  addListItem(value: GenericDropdownOptions) {
    if (this.isMobileOrTablet && this.isScrolling) {
      return;
    }

    if (this.isAlreadyAdding) {
      return;
    }
    this.isAlreadyAdding = true;

    if (isNull(this.optionList) && !this.isSingle) {
      this.optionList = [value];
    } else if (!this.isSingle) {
      const foundOption = this.optionList.find(
        (option) => option.value === value.value,
      );
      if (!foundOption) {
        this.optionList.push(value);
      } else {
        this.optionList = [
          ...this.optionList.filter(
            (itemSelected) => itemSelected.value !== value.value,
          ),
        ];
      }
    } else {
      this.optionList = [];
      this.optionList.push(value);
    }

    this.onChange(this.optionList);
    this.selectedItem.emit(this.optionList);
    this.markAsTouched();

    // Wait before setting the check to false.
    // This is so that multiple different events are not fired.
    setTimeout(() => {
      this.isAlreadyAdding = false;
    }, 10);
  }

  removeFromList(item: GenericDropdownOptions) {
    event?.stopPropagation();
    this.optionList = [
      ...this.optionList.filter(
        (itemSelected) => itemSelected.value !== item.value,
      ),
    ];
    this.onChange(this.optionList);
    this.selectedItem.emit(this.optionList);
    this.markAsTouched();
  }
  ngOnInit() {
    this.isPrefix = this.type.includes("prefix");
    this.isTertiary = this.type.includes("tertiary");
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      this.isMobileOrTablet = true;
    }
  }

  public writeValue(value: GenericDropdownOptions[]): void {
    this.optionList = value;
  }

  input(event: any) {
    this.onChange(event.value);
    this.markAsTouched();
  }
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = false;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
      this.touchedEvent.next(true);
    }
  }

  search(e: string) {
    const val = e.toLowerCase();
    this.searchList = this.options.filter((x) => {
      if (x.optionName.toLowerCase().indexOf(val) !== -1 || !val) {
        return x;
      } else {
        return;
      }
    });
  }

  @HostListener("body:mouseup", ["$event"])
  @HostListener("window:touchend", ["$event"])
  handleClickAway(event: MouseEvent) {
    if (!this.showList) {
      return;
    }
    const list = document.getElementsByClassName("options");
    const nodeArr = Array.from(list);
    const found = nodeArr.find((element) => element === event.target);

    const searchBar = document.getElementsByClassName("search-bar");
    const searchBarArr = Array.from(searchBar);
    const foundSearchBar = searchBarArr.find(
      (element) => element === event.target,
    );

    if (this.isSingle && !foundSearchBar) {
      if (this.isMobileOrTablet && this.isScrolling) {
        return;
      }
      this.showList = false;
      this.keyword = "";
      this.searchList = this.options;
      this.markAsTouched();
      return;
    }

    if (!found) {
      this.showList = false;
      this.keyword = "";
      this.searchList = this.options;
      this.markAsTouched();
      event.stopPropagation();
    }
  }
}
