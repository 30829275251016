import { initialAuthState } from "./auth.state";
import { createReducer, on } from "@ngrx/store";
import {
  getMyAuthInfoSuccess,
  login,
  loginFailure,
  loginSuccess,
  logoutSuccess,
  resetPassword,
  resetPasswordFailure,
  resetPasswordSuccess,
  signup,
  signupFailure,
  signupSuccess,
} from "./auth.actions";
import {
  loadingState,
  loadingStateFailure,
  loadingStateSuccess,
} from "@shared/helpers/loading-state";

export const authReducer = createReducer(
  initialAuthState,
  on(login, (state) => ({
    ...state,
    login: loadingState(true),
  })),
  on(loginSuccess, (state, payload) => ({
    ...state,
    login: loadingStateSuccess(),
    loggedIn: true,
    user: payload.user,
    permissions: payload.permissions,
    token: payload.token,
  })),
  on(loginFailure, (state, payload) => ({
    ...state,
    login: loadingStateFailure(payload.message, payload.error),
  })),
  on(signup, (state) => ({
    ...state,
    signup: loadingState(true),
  })),
  on(signupSuccess, (state) => ({
    ...state,
    signup: loadingStateSuccess(),
  })),
  on(signupFailure, (state, payload) => ({
    ...state,
    signup: loadingStateFailure(payload.message, payload.error),
  })),
  on(resetPassword, (state) => ({
    ...state,
    resetPassword: loadingState(true),
  })),
  on(resetPasswordSuccess, (state) => ({
    ...state,
    resetPassword: loadingStateSuccess(),
  })),
  on(resetPasswordFailure, (state, payload) => ({
    ...state,
    resetPassword: loadingStateFailure(payload.message, payload.error),
  })),
  on(getMyAuthInfoSuccess, (state, payload) => ({
    ...state,
    login: loadingStateSuccess(),
    loggedIn: !!payload?.user,
    user: payload.user,
    permissions: payload.permissions,
    token: payload.token,
    firstLoad: true,
  })),
  on(logoutSuccess, (state, payload) => ({
    ...state,
    login: loadingStateSuccess(),
    loggedIn: false,
    user: undefined,
    permissions: [],
    token: undefined,
  })),
);
