export const profile = {
  companyStatus: [
    {
      checked: false,
      value: "Current",
      displayName: "Current",
      optionName: "Current",
    },
    {
      checked: false,
      value: "Acting",
      displayName: "Acting",
      optionName: "Acting",
    },
    {
      checked: false,
      value: "Designate",
      displayName: "Designate",
      optionName: "Designate",
    },
    {
      checked: false,
      value: "Former",
      displayName: "Former",
      optionName: "Former",
    },
    {
      checked: false,
      value: "Incoming",
      displayName: "Incoming",
      optionName: "Incoming",
    },
    {
      checked: false,
      value: "Interim",
      displayName: "Interim",
      optionName: "Interim",
    },
    {
      checked: false,
      value: "Outgoing",
      displayName: "Outgoing",
      optionName: "Outgoing",
    },
  ],
  committeeChairmanships: {
    chairOfAuditCommittee: "Chair of Audit Committee",
    chairOfRiskCommittee: "Chair of Risk Committee",
    chairOfRemunerationCommittee: "Chair of Remuneration Committee",
    chairOfEsgCommittee: "Chair of ESG Committee",
    chairOfInvestmentCommittee: "Chair of Investment Committee ",
  },
};
