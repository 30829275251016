import { createAction, props } from "@ngrx/store";
import { StandardError } from "@shared/models/standard-error.model";

export const setSocketId = createAction(
  "[Realtime] Set Socket Id",
  props<{ socketId: string }>(),
);

export const joinRooms = createAction("[Realtime] Join Room");
export const joinRoomsSuccess = createAction("[Realtime] Join Room Success");
export const joinRoomsFailure = createAction(
  "[Realtime] Join Room Failure",
  props<StandardError>(),
);
