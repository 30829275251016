export interface LoadingState {
  loading: boolean;
  success?: boolean;
  error?: string;
  errorDetails?: any;
}

export function loadingState(loading = false): LoadingState {
  return {
    loading,
  };
}

export function loadingStateSuccess(): LoadingState {
  return {
    loading: false,
    success: true,
  };
}

export function loadingStateFailure(
  error: string,
  errorDetails?: any,
): LoadingState {
  return {
    loading: false,
    success: false,
    error,
    errorDetails,
  };
}
