import { loadingState, LoadingState } from "@shared/helpers/loading-state";
import { TokenInfo } from "../models/responses/user-login-response.dto";
import { AuthUser } from "@models";
import { Permission } from "src/app/modules/auth/models/permission.model";

export interface AuthState {
  login: LoadingState;
  signup: LoadingState;
  loggedIn: boolean;
  resetPassword: LoadingState;
  user: AuthUser | undefined;
  permissions: Permission[];
  token: TokenInfo | undefined;
  firstLoad: boolean;
}

export const initialAuthState: AuthState = {
  login: loadingState(),
  signup: loadingState(),
  loggedIn: false,
  resetPassword: loadingState(),
  user: undefined,
  permissions: [],
  token: undefined,
  firstLoad: false,
};
