import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { ActionTracker } from "@shared/store/action-trackers/action-tracker.model";
import { createFeatureSelector, createReducer, on } from "@ngrx/store";
import * as ActionTrackerActions from "./action-tracker.actions";

export const actionTrackerFeatureKey = "actionTracker";
export interface State extends EntityState<ActionTracker> {
  count: number;
}

export const selectActionTrackerState = createFeatureSelector<State>(
  actionTrackerFeatureKey,
);

export const trackedActionEntityAdapter = createEntityAdapter<ActionTracker>({
  selectId: (entity) => entity.trackingId,
});

export const initialState: EntityState<ActionTracker> & { count: number } =
  trackedActionEntityAdapter.getInitialState({
    count: 0,
  });

export const DEFAULT_TRACKING_ID = "DEFAULT";
export function getTrackingId(action: any) {
  return action?.trackingId || DEFAULT_TRACKING_ID;
}

export const reducer = createReducer(
  initialState,
  on(ActionTrackerActions.addActionTracker, (state, { actionTracker }) => {
    return {
      ...trackedActionEntityAdapter.addOne(actionTracker, state),
      count: state.count + 1,
    };
  }),

  on(ActionTrackerActions.completeActionTracker, (state, action) => {
    const updatedState = trackedActionEntityAdapter.updateOne(
      {
        id: getTrackingId(action.action),
        changes: {
          ...action,
          success: true,
          loading: false,
          message: action?.data?.message,
          data: action?.data?.data,
        },
      },
      state,
    );
    return { ...updatedState, count: state.count - 1 };
  }),

  on(ActionTrackerActions.failActionTracker, (state, action) => {
    const updatedState = trackedActionEntityAdapter.updateOne(
      {
        id: getTrackingId(action.action),
        changes: {
          ...action,
          success: false,
          loading: false,
          message: action?.data?.message,
        },
      },
      state,
    );
    return { ...updatedState, count: state.count - 1 };
  }),

  on(ActionTrackerActions.deleteActionTracker, (state, { id }) => {
    const updatedState = trackedActionEntityAdapter.removeOne(id, state);
    return { ...updatedState, count: state.count - 1 };
  }),

  on(ActionTrackerActions.clearActionTracker, (state) => {
    const updatedState = trackedActionEntityAdapter.removeAll({
      ...state,
      selectedActionTrackerId: null,
    });
    return { ...updatedState, count: 0 };
  }),
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  trackedActionEntityAdapter.getSelectors(selectActionTrackerState);
