import { NgModule, ErrorHandler } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ApiModule } from "@shared/api-client";
import { SharedModule } from "@shared/shared.module";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { ActionTrackerModule } from "@shared/store/action-trackers/action-tracker.module";
import { SelectOptionsModule } from "./modules/select-options/select-options.module";
import { metaReducers, reducers } from "./root-store";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RealtimeModule } from "src/app/modules/realtime/realtime.module";
import { AuthModule } from "src/app/modules/auth/auth.module";
import { environment } from "@environments/environment";
import { GoogleTagManagerModule } from "angular-google-tag-manager";
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from "ngx-cookieconsent";
import { InformationModule } from "./modules/information/information.module";
import * as Sentry from "@sentry/angular-ivy";

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.apiBaseUrl,
  },
  position: "bottom",
  theme: "classic",
  palette: {
    popup: {
      background: "#fdfaf3",
      text: "#2e2828",
      link: "#ffffff",
    },
    button: {
      background: "#2e2828",
      text: "#fdfaf3",
      border: "transparent",
    },
  },
  type: "info",
  content: {
    message:
      "This website uses cookies to ensure you get the best experience on our website.",
    dismiss: "Accept",
    deny: "Refuse cookies",
    link: "Learn more",
    href: environment.privacyPolicy,
    policy: "Cookie Policy",
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    NgcCookieConsentModule.forRoot(cookieConfig),
    BrowserModule,
    AppRoutingModule,
    ApiModule,
    SharedModule,
    AuthModule,
    SelectOptionsModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([]),
    ActionTrackerModule,
    FormsModule,
    ReactiveFormsModule,
    StoreDevtoolsModule.instrument({
      maxAge: 100, // Retains last 100 states
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    BrowserAnimationsModule,
    RealtimeModule,
    InformationModule,
    GoogleTagManagerModule.forRoot({
      id: environment.gtmTag,
    }),
  ],
  providers: [
    { provide: "googleTagManagerCSPNonce", useValue: "CSP-NONCE" },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        logErrors: true,
      }),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
