import { Component, Input } from "@angular/core";
import { ActionTracker } from "@shared/store/action-trackers/action-tracker.model";
import { AuthClientApiService } from "@shared/api-client";
import { map } from "rxjs";

@Component({
  selector: "app-unverified-log-in-exception-retry",
  templateUrl: "./unverified-log-in-exception-retry.component.html",
  styleUrls: ["./unverified-log-in-exception-retry.component.scss"],
})
export class UnverifiedLogInExceptionRetryComponent {
  @Input() action: any;
  emailSent: boolean = false;
  constructor(private authService: AuthClientApiService) {}
  sendVerificationEmail() {
    //Send email verification here
    this.authService
      .authControllerResendVerificationEmail({
        email: this.action.email,
      })
      .pipe(
        map(() => {
          this.emailSent = true;
        }),
      )
      .subscribe();
  }
}
