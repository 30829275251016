import { createAction, props } from "@ngrx/store";
import { FooterModel } from "@models";
import { GetFooterItemsDto } from "@shared/api-client";

const actionPrefix: string = `[Footer]`;

export const getFooterItemsReq = createAction(
  `${actionPrefix} Get Footer Items Request`,
  props<GetFooterItemsDto>(),
);

export const getFooterItemsSuccess = createAction(
  `${actionPrefix} Get Footer Items Success`,
  props<{ footerItems: FooterModel[] }>(),
);
