import { CanActivateFn, Router } from "@angular/router";
import { AuthService } from "../auth.service";
import { inject } from "@angular/core";

export const refreshGuard: CanActivateFn = (route, state) => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);

  if (authService.isRefreshTokenExpired()) {
    return router.parseUrl("/auth/login");
  }
  return true;
};
