import { GeneralEntityState } from "@shared/store/models/general-entity-state.model";
import { StateHelper } from "@shared/helpers/state.helper";
import { ActionCreator, ReducerTypes } from "@ngrx/store";
import { DefaultRequestActions } from "@shared/helpers/default-request.actions";

export class ApiStateHelper<
  E = any,
  Create = any,
  Update = any,
  S extends GeneralEntityState<E> = GeneralEntityState<E>,
> extends StateHelper<E, S> {
  public requestActions = new DefaultRequestActions<E, Create, Update>(
    this.name,
  );
  public apiOns: ReducerTypes<S, ActionCreator[]>[] = [];
}
