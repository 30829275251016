import { CanActivateFn, Router } from "@angular/router";
import { inject } from "@angular/core";
import { AuthService } from "../auth.service";
import { map, tap } from "rxjs";

export const loggedOutGuard: CanActivateFn = (route, state) => {
  const router: Router = inject(Router);
  const authService: AuthService = inject(AuthService);

  return authService.isAuthenticated().pipe(
    tap((authenticated) => {
      if (authenticated) {
        router.navigate(["/home"]);
      }
    }),
    // In this case, we want the guard to return true if not authenticated
    // And false if you are authenticated
    map((value) => !value),
  );
};
