import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from "@angular/core";
import { Observable, Subject, takeUntil } from "rxjs";
import { ActionTracker } from "@shared/store/action-trackers/action-tracker.model";
import { TrackingHelpers } from "@shared/store/action-trackers/services/team-350.store";
import type { ModalOptions } from "flowbite";
import { Modal } from "flowbite";
import { ActivatedRoute } from "@angular/router";
import { TokenOtpTypeEnum } from "../../../otp/enums/token-otp-type.enum";

@Component({
  selector: "app-general-alert-modal",
  templateUrl: "./general-alert-modal.component.html",
  styleUrls: ["./general-alert-modal.component.scss"],
})
export class GeneralAlertModalComponent implements AfterViewInit, OnDestroy {
  destroy$: Subject<void> = new Subject();
  isLoading$: Observable<boolean | undefined> | undefined = new Observable<
    boolean | undefined
  >();
  success$: Observable<boolean | undefined> | undefined = new Observable<
    boolean | undefined
  >();
  result$: Observable<ActionTracker> = new Observable<ActionTracker>();

  errorModalTitle: string = "Something went wrong";
  errorModalMessage: string = "";
  successModalMessage: string = "";
  secondaryMessage: string = "";
  verifySignupOtp: boolean = false;
  verifyOtp: boolean = false;
  verifyResetPasswordOtp: boolean = false;

  @Input() onlyShowError: boolean = false;

  @Input()
  set action(value: TrackingHelpers) {
    this._action = value;
    if (value) {
      this.init();
    }
  }

  //@ts-ignore
  _action: TrackingHelpers;

  failureModal: Modal;
  successModal: Modal;

  @ViewChild("failureTemplate") failureModalTemplate?: ElementRef;
  @ViewChild("successTemplate") successModalTemplate?: ElementRef;

  modalOptions?: ModalOptions;

  @Output() navigateToPage = new EventEmitter<void>();

  retryVerification: boolean = false;
  errorAction: ActionTracker;

  constructor(private route: ActivatedRoute) {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.verifySignupOtp = params["type"] === TokenOtpTypeEnum.signup;
      this.verifyOtp = params["type"] === TokenOtpTypeEnum.verify;
      this.verifyResetPasswordOtp =
        params["type"] === TokenOtpTypeEnum.resetPassword;
    });
  }

  ngAfterViewInit(): void {
    this.modalOptions = {
      placement: "center-right",
      closable: true,
    };
    this.successModal = new Modal(
      this.successModalTemplate?.nativeElement,
      this.modalOptions,
    );
    this.failureModal = new Modal(
      this.failureModalTemplate?.nativeElement,
      this.modalOptions,
    );
    this.failureModal.hide();
    this.successModal.hide();
  }

  private init() {
    this.isLoading$ = this._action.isLoading$.pipe(takeUntil(this.destroy$));
    this.success$ = this._action.success$.pipe(takeUntil(this.destroy$));
    this.result$ = this._action.onComplete().pipe(takeUntil(this.destroy$));
    this.result$.subscribe(
      (result) => {
        if (this.onlyShowError) {
          return;
        }

        this.successModalMessage = result.message;

        if (this.verifySignupOtp) {
          this.secondaryMessage = "You will receive an email on approval.";
        }
        this.showSuccessModal();
      },
      (error) => {
        const errorStatusCode: number =
          error?.data?.error?.status || error?.data?.error?.error?.status;
        if (errorStatusCode === 420) {
          this.errorAction = error.action;
          this.retryVerification = true;
        }
        const errorPayload =
          error?.data?.error?.payload || error?.data?.error?.error?.payload;
        this.errorModalMessage = errorPayload?.message || error.message;
        this.errorModalTitle = errorPayload?.title || this.errorModalTitle;
        this.showErrorModal();
        return error.message;
      },
    );
  }
  private showSuccessModal() {
    this.successModal = new Modal(
      this.successModalTemplate?.nativeElement,
      this.modalOptions,
    );
    this.successModal.show();
  }

  private showErrorModal() {
    this.failureModal = new Modal(
      this.failureModalTemplate?.nativeElement,
      this.modalOptions,
    );
    this.failureModal.show();
  }

  ngOnDestroy() {
    // Ensure that the modals are closed in the event that the component gets destroyed
    this.failureModal.hide();
    this.successModal.hide();

    this.destroy$.next();
    this.destroy$.complete();
  }

  closeFailureModal() {
    this.failureModal.hide();
  }
  closeSuccessModal() {
    this.navigateToPage.emit();
    this.successModal.hide();
  }
}
