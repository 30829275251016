import { createReducer, on } from "@ngrx/store";
import { initialSelectOptionsState } from "./select-options.state";
import {
  getCompanyRoles,
  getCompanyRolesFailure,
  getCompanyRolesSuccess,
  getExpertise,
  getExpertiseFailure,
  getExpertiseSuccess,
  getInterests,
  getInterestsFailure,
  getInterestsSuccess,
  getLocation,
  getLocationFailure,
  getLocationSuccess,
  getSectorExperience,
  getSectorExperienceError,
  getSectorExperienceSuccess,
  getSignupSelectOptions,
  getSignupSelectOptionsFailure,
  getUserSkills,
  getUserSkillsFailure,
  getUserSkillsSuccess,
} from "./select-options.actions";
import {
  loadingState,
  loadingStateFailure,
  loadingStateSuccess,
} from "@shared/helpers/loading-state";

export const selectOptionsReducer = createReducer(
  initialSelectOptionsState,
  on(getSectorExperience, (state) => ({
    ...state,
    getSectorExperience: loadingState(true),
  })),
  on(getSectorExperienceSuccess, (state, payload) => ({
    ...state,
    getSectorExperience: loadingStateSuccess(),
    platformSectorExperiences: payload.data,
  })),
  on(getSectorExperienceError, (state, payload) => ({
    ...state,
    getSectorExperience: loadingStateFailure(payload.message, payload.error),
  })),

  on(getLocation, (state) => ({
    ...state,
    getLocation: loadingState(true),
  })),
  on(getLocationSuccess, (state, payload) => ({
    ...state,
    getLocation: loadingStateSuccess(),
    locations: payload.data,
  })),
  on(getLocationFailure, (state, payload) => ({
    ...state,
    getLocation: loadingStateFailure(payload.message, payload.error),
  })),

  on(getInterests, (state) => ({
    ...state,
    getInterests: loadingState(true),
  })),
  on(getInterestsSuccess, (state, payload) => ({
    ...state,
    getInterests: loadingStateSuccess(),
    platformInterests: payload.data,
  })),
  on(getInterestsFailure, (state, payload) => ({
    ...state,
    getInterests: loadingStateFailure(payload.message, payload.error),
  })),

  on(getExpertise, (state) => ({
    ...state,
    getExpertise: loadingState(true),
  })),
  on(getExpertiseSuccess, (state, payload) => ({
    ...state,
    getExpertise: loadingStateSuccess(),
    platformExpertise: payload.data,
  })),
  on(getExpertiseFailure, (state, payload) => ({
    ...state,
    getExpertise: loadingStateFailure(payload.message, payload.error),
  })),

  on(getSignupSelectOptions, (state) => ({
    ...state,
    getSignupSelectOptions: loadingState(true),
  })),

  on(getSignupSelectOptionsFailure, (state, payload) => ({
    ...state,
    getSignupSelectOptions: loadingStateFailure(payload.message, payload.error),
  })),

  on(getUserSkills, (state) => ({
    ...state,
    getUserSkills: loadingState(true),
  })),
  on(getUserSkillsSuccess, (state, payload) => ({
    ...state,
    getUserSkills: loadingStateSuccess(),
    userSkills: payload.data,
  })),
  on(getUserSkillsFailure, (state, payload) => ({
    ...state,
    getUserSkills: loadingStateFailure(payload.message, payload.error),
  })),

  on(getCompanyRoles, (state) => ({
    ...state,
    getCompanyRoles: loadingState(true),
  })),
  on(getCompanyRolesSuccess, (state, payload) => ({
    ...state,
    getCompanyRoles: loadingStateSuccess(),
    companyRoles: payload.data,
  })),
  on(getCompanyRolesFailure, (state, payload) => ({
    ...state,
    getCompanyRoles: loadingStateFailure(payload.message, payload.error),
  })),
);
