export const user = {
  team350Club: {
    inviteUserText:
      "This comes to invite you to join the 350 Club, the closed community platform for FTSE 350 board members and FTSE 100 executive committee members. Free to join, it has over 750 members covering almost all of the FTSE 100 index and nearly 90% of the FTSE 250 index.\n" +
      "\n" +
      "The Club offers the ability for the first time to search, meet and message FTSE 350 peers across sector and geography to compare notes confidentially on board and business issues. Read more about the club and register to join.",
  },
  boardhouse: {
    inviteUserText:
      "This comes to invite you to join Boardhouse, the private peer community enabling FTSE 100 executive committee -1 members," +
      " FTSE 250 executive committee members (non board) and the largest UK subsidiary board members to interact under the Chatham House rule to search," +
      "meet and message peers.",
  },
};
