import { ActionCreator, on, ReducerTypes } from "@ngrx/store";
import {
  getUnreadMessageCountSuccess,
  incrementUnreadMessage,
} from "src/app/modules/member-root/modules/message/store/message.actions";
import { MessageState } from "src/app/modules/member-root/modules/message/store/message.ngrx";

export const customMessageReducers: ReducerTypes<
  MessageState,
  ActionCreator[]
>[] = [
  on(getUnreadMessageCountSuccess, (state, action) => {
    return {
      ...state,
      unreadMessageCount: action.unreadCount,
    };
  }),
  on(incrementUnreadMessage, (state, action) => {
    return {
      ...state,
      unreadMessageCount: state.unreadMessageCount + 1,
    };
  }),
];
