import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

import { CountryCode, isValidPhoneNumber } from "libphonenumber-js";

export function phoneNumberValidator(countryCode: CountryCode): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const isValid: boolean = isValidPhoneNumber(value, countryCode);

    return !isValid ? { validPhoneNumber: true } : null;
  };
}
