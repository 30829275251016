import { SystemPageNameInterface } from "@shared/interfaces/system-page-name.interface";

export const footer: SystemPageNameInterface[] = [
  { systemPageName: `privacy-policy`, route: `/info/privacy-policy` },
  { systemPageName: `about`, route: `/info/about` },
  {
    systemPageName: `terms-and-conditions`,
    route: `/info/terms-and-conditions`,
  },
];
