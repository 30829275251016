import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { Injector, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { environment } from "@environments/environment";
import { NgxMasonryModule } from "ngx-masonry";
import { GeneralCardComponent } from "./general-card/general-card.component";
import { GeneralCreateCardComponent } from "./general-card/general-create-card/general-create-card.component";
import { GeneralCreateModalComponent } from "./general-card/general-create-card/general-create-modal/general-create-modal.component";
import { LoggedOutHeaderComponent } from "@shared/components/logged-out-header/logged-out-header.component";
import { RouterModule } from "@angular/router";
import { HasPermissionDirective } from "@shared/directives/has-permission.directive";
import { SearchBarModule } from "@shared/modules/search-bar/search-bar.module";
import { GeneralAlertModalComponent } from "@shared/components/general-alert-modal/general-alert-modal.component";
import { FilterComponent } from "@shared/modules/filter/components/filter-component/filter.component";
import { FilterModalComponent } from "./modules/filter/components/filter-modal-component/filter-modal.component";
import { MultiselectCheckboxGroupComponent } from "@shared/components/checkbox/multiselect-checkbox/multiselect-checkbox-group.component";
import { CheckboxComponent } from "@shared/components/checkbox/checkbox/checkbox.component";
import { UnderlineTabsComponent } from "@shared/components/underline-tabs/underline-tabs.component";
import { SortComponent } from "./modules/sort/components/sort.component";
import { FilterSearchAndSortComponent } from "./filter-search-and-sort/filter-search-and-sort.component";
import { CardResultsComponent } from "@shared/general-results/components/card-results/card-results.component";
import { GeneralResultsCardComponent } from "@shared/general-results/components/general-results-card/general-results-card.component";
import { SkillsMultiSelectComponent } from "@shared/components/skills-multi-select/skills-multi-select.component";
import { GeneralToggleComponentComponent } from "@shared/components/general-toggle-component/general-toggle-component.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ListResultsComponent } from "./general-results/components/list-results/list-results.component";
import { GeneralResultsListComponent } from "./general-results/components/general-results-list/general-results-list.component";
import { DropdownComponent } from "@shared/components/dropdown-component/dropdown-component.component";
import { MemberRootBannerComponent } from "@shared/components/member-root-banner/member-root-banner.component";
import { GeneralUploaderComponent } from "./components/general-uploader/general-uploader.component";
import { UnsortedListResultsComponent } from "./general-results/components/unsorted-list-results/unsorted-list-results.component";
import { AddToCalendarDropdownComponent } from "./components/add-to-calendar-dropdown/add-to-calendar-dropdown.component";
import { DatePickerInputComponent } from "./components/date-picker-input/date-picker-input.component";
import { MemberSearchBarComponent } from "./components/member-search-bar/member-search-bar.component";
import { DateRangePickerComponent } from "./components/date-range-picker/date-range-picker.component";
import { GeneralUserProfilePictureComponent } from "./components/general-user-profile-picture/general-user-profile-picture.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { ChatDateFormatPipe } from "@shared/pipes/chat-date-format.pipe";
import { AccordionComponent } from "./components/accordion/accordion.component";
import { AccordionItemComponent } from "@shared/components/accordion/accordion-item/accordion-item.component";
import { GeneralCompanyLogoComponent } from "./components/general-company-logo/general-company-logo.component";
import { FileSizeFormatPipe } from "@shared/pipes/file-size-format.pipe";
import { ViewAttachmentComponent } from "./components/view-attachment/view-attachment.component";
import { GeneralImageViewerComponent } from "./components/general-image-viewer/general-image-viewer.component";
import { NewsListItemComponent } from "@shared/news-list-item/news-list-item.component";
import { PostDataFormatPipe } from "@shared/pipes/post-data-format.pipe";
import { UnverifiedLogInExceptionRetryComponent } from "./components/exception-retries/unverified-log-in-exception-retry/unverified-log-in-exception-retry.component";
import { ThreeDotDropdownComponent } from "./components/three-dot-dropdown/three-dot-dropdown.component";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { SpinnerAndTextComponent } from "./components/spinner-and-text/spinner-and-text.component";
import { ContentTagInputComponent } from "@shared/components/content-tag-input/content-tag-input.component";
import { DateTimePickerInputComponent } from "@shared/components/date-time-picker/date-time-picker-input.component";
import { DateSuffixPipe } from "@shared/pipes/date-suffix.pipe";
import { RoomListItemComponent } from "@shared/components/room-list-item/room-list-item.component";
import { GeneralConfirmModalComponent } from "./components/general-confirm-modal/general-confirm-modal.component";
import { DigitOnlyDirective } from "@shared/directives/digit-only.directive";
import { SurveyModule } from "survey-angular-ui";
import { PremiumRoomNotificationModalComponent } from "@shared/components/premium-room-notification-modal/premium-room-notification-modal.component";
import { PremiumMentorshipNotificationModalComponent } from "@shared/components/premium-mentorship-notification-modal/premium-mentorship-notification-modal.component";
import { UserProfileIncompleteNotificationComponent } from "./components/user-profile-incomplete-notification/user-profile-incomplete-notification.component";
import { FilterDropdownComponent } from "./modules/filter/components/filter-dropdown/filter-dropdown.component";

/**
 * Set the base path to nothing so that it'll adjust based on the environment
 */
Injector.create({
  providers: [{ provide: "baseUrl", useValue: environment.apiBaseUrl }],
});

@NgModule({
  declarations: [
    ContentTagInputComponent,
    GeneralCardComponent,
    GeneralCreateCardComponent,
    GeneralCreateModalComponent,
    LoggedOutHeaderComponent,
    HasPermissionDirective,
    GeneralAlertModalComponent,
    FilterComponent,
    FilterModalComponent,
    MultiselectCheckboxGroupComponent,
    CheckboxComponent,
    UnderlineTabsComponent,
    SortComponent,
    FilterSearchAndSortComponent,
    CardResultsComponent,
    GeneralResultsCardComponent,
    SkillsMultiSelectComponent,
    GeneralToggleComponentComponent,
    ListResultsComponent,
    GeneralResultsListComponent,
    DropdownComponent,
    MemberRootBannerComponent,
    GeneralUploaderComponent,
    UnsortedListResultsComponent,
    AddToCalendarDropdownComponent,
    DatePickerInputComponent,
    MemberSearchBarComponent,
    DateRangePickerComponent,
    GeneralUserProfilePictureComponent,
    BreadcrumbComponent,
    ChatDateFormatPipe,
    AccordionComponent,
    AccordionItemComponent,
    GeneralCompanyLogoComponent,
    FileSizeFormatPipe,
    ViewAttachmentComponent,
    GeneralImageViewerComponent,
    NewsListItemComponent,
    PostDataFormatPipe,
    UnverifiedLogInExceptionRetryComponent,
    ThreeDotDropdownComponent,
    SpinnerComponent,
    SpinnerAndTextComponent,
    DateTimePickerInputComponent,
    DateSuffixPipe,
    RoomListItemComponent,
    GeneralConfirmModalComponent,
    DigitOnlyDirective,
    PremiumRoomNotificationModalComponent,
    PremiumMentorshipNotificationModalComponent,
    UserProfileIncompleteNotificationComponent,
    FilterDropdownComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    InfiniteScrollModule,
    NgxMasonryModule,
    RouterModule,
    ReactiveFormsModule,
    SearchBarModule,
    SurveyModule,
  ],
  exports: [
    ContentTagInputComponent,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    SearchBarModule,
    NgxMasonryModule,
    GeneralCardComponent,
    GeneralCreateCardComponent,
    LoggedOutHeaderComponent,
    DropdownComponent,
    GeneralAlertModalComponent,
    FilterComponent,
    FilterModalComponent,
    UnderlineTabsComponent,
    SortComponent,
    FilterSearchAndSortComponent,
    CardResultsComponent,
    GeneralResultsCardComponent,
    SkillsMultiSelectComponent,
    GeneralToggleComponentComponent,
    GeneralResultsListComponent,
    ListResultsComponent,
    MemberRootBannerComponent,
    GeneralUploaderComponent,
    UnsortedListResultsComponent,
    AddToCalendarDropdownComponent,
    DatePickerInputComponent,
    MemberSearchBarComponent,
    GeneralUserProfilePictureComponent,
    BreadcrumbComponent,
    ChatDateFormatPipe,
    AccordionComponent,
    AccordionItemComponent,
    GeneralCompanyLogoComponent,
    FileSizeFormatPipe,
    ViewAttachmentComponent,
    GeneralImageViewerComponent,
    HasPermissionDirective,
    NewsListItemComponent,
    PostDataFormatPipe,
    ThreeDotDropdownComponent,
    SpinnerComponent,
    SpinnerAndTextComponent,
    DateTimePickerInputComponent,
    DateSuffixPipe,
    RoomListItemComponent,
    GeneralConfirmModalComponent,
    DigitOnlyDirective,
    SurveyModule,
    PremiumRoomNotificationModalComponent,
    PremiumMentorshipNotificationModalComponent,
    UserProfileIncompleteNotificationComponent,
    FilterDropdownComponent,
  ],
})
export class SharedModule {}
