import { createAction, props } from "@ngrx/store";
import { Update } from "@ngrx/entity";

export class DefaultStoreActions<Entity> {
  constructor(public name: string) {}

  set = createAction(`[${this.name}] Set`, props<{ items: Entity[] }>());
  setPage = createAction(
    `[${this.name}] Set Page`,
    props<{ items: Entity[] }>(),
  );

  addOne = createAction(`[${this.name}] Add One`, props<{ item: Entity }>());
  addMany = createAction(
    `[${this.name}] Add Many`,
    props<{ items: Entity[] }>(),
  );

  upsertOne = createAction(
    `[${this.name}] Upsert One`,
    props<{ item: Entity }>(),
  );
  upsertMany = createAction(
    `[${this.name}] Upsert Many`,
    props<{ items: Entity[] }>(),
  );

  updateOne = createAction(
    `[${this.name}] Update One`,
    props<{ item: Update<Entity> }>(),
  );
  updateMany = createAction(
    `[${this.name}] Update Many`,
    props<{ items: Update<Entity>[] }>(),
  );

  removeOne = createAction(
    `[${this.name}] Remove One`,
    props<{ item: Entity }>(),
  );
  removeMany = createAction(
    `[${this.name}] Remove Many`,
    props<{ items: Entity[] }>(),
  );

  addToPage = createAction(
    `[${this.name}] Add To Page`,
    props<{ items: Entity[]; addToTop?: boolean }>(),
  );

  clear = createAction(`[${this.name}] Clear`);
}
