import { createAction, props } from "@ngrx/store";
import { AddParticipantsDto } from "@shared/api-client";

const actionLabel = "[Chat]";

class setUnreadChatDto {
  chatId: string;
}

class removeChatIdFromUnreadListDto {
  chatId: string;
}
export const addParticipantsReq = createAction(
  `${actionLabel} Add Chat Participants`,
  props<AddParticipantsDto>(),
);

export const setUnreadChatId = createAction(
  `${actionLabel} Set Unread Chat`,
  props<setUnreadChatDto>(),
);

export const removeChatIdFromUnreadList = createAction(
  `${actionLabel} Removing ChatId from unread list`,
  props<removeChatIdFromUnreadListDto>(),
);

export const getUnreadChatsReq = createAction(
  `${actionLabel} Get Unread Chats`,
);
export const getUnreadChatIdsSuccess = createAction(
  `${actionLabel} Get Unread Chat Ids Success`,
  props<{ chatIds: string[] }>(),
);
