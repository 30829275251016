import { NgModule } from "@angular/core";
import { SharedModule } from "@shared/shared.module";
import { FooterModule } from "../footer/footer.module";
import { InformationComponent } from "./components/information.component";
import { InformationRoutingModule } from "./information-routing.module";

@NgModule({
  declarations: [InformationComponent],
  imports: [SharedModule, InformationRoutingModule, FooterModule],
})
export class InformationModule {}
