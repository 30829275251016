import { Component, OnInit } from "@angular/core";
import { initFlowbite } from "flowbite";
import { RealtimeClientManagementService } from "src/app/modules/realtime/services/realtime-client-management.service";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { NavigationEnd, Router } from "@angular/router";
import { NgcCookieConsentService } from "ngx-cookieconsent";
import { environment } from "@environments/environment";
import Hotjar from "@hotjar/browser";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private realtime: RealtimeClientManagementService,
    private gtmService: GoogleTagManagerService,
    private cookieService: NgcCookieConsentService,
  ) {
    if (environment.production) {
      Hotjar.init(
        Number(environment.hotjarTrackingCode),
        Number(environment.hotjarVersion),
      );
    }
  }

  ngOnInit() {
    initFlowbite();
    this.realtime.init();
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd && environment.production) {
        const gtmTag = {
          event: "page",
          pageName: item.url,
        };

        this.gtmService.pushTag(gtmTag);
      }
    });
  }
}
