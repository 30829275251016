import { GeneralEntityState } from "@shared/store/models/general-entity-state.model";
import {
  ActionCreator,
  createAction,
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
  on,
  props,
  ReducerTypes,
} from "@ngrx/store";
import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { EntitySelectors } from "@ngrx/entity/src/models";
import { DefaultStoreActions } from "@shared/helpers/default-store.actions";
import {
  defaultPaginationState,
  PaginationEntityState,
} from "@shared/store/models/pagination-entity-state.model";
import {
  defaultQueryState,
  QueryEntityState,
  QueryField,
  SortField,
} from "@shared/store/models/query-entity-state.model";
import { SortOrder } from "@shared/enums/query-helper/sort-order.enum";
import { GlobalState } from "../../../root-store";
import { uniq } from "lodash";
import { ExcludeOverlap } from "@shared/types/exclude-overlap.type";

type RequiredInitialState<Child, Parent> = Partial<Parent> &
  ExcludeOverlap<Child, Parent>;

export class StateHelper<
  E,
  S extends GeneralEntityState<E> = GeneralEntityState<E>,
> {
  public featureSelector: MemoizedSelector<GlobalState, S>;
  public entityAdapter: EntityAdapter<E>;
  public entitySelectors: EntitySelectors<E, S>;
  public storeActions: DefaultStoreActions<E> = new DefaultStoreActions<E>(
    this.name,
  );
  private _initialState: any;

  constructor(
    public name: string,
    public featureStateKey: string,
    public entityIdKey: string,
  ) {
    this.featureSelector = createFeatureSelector<S>(featureStateKey);
    this.entityAdapter = createEntityAdapter<E>({
      // @ts-ignore
      selectId: (entity) => entity[entityIdKey],
    });
    this.entitySelectors = this.entityAdapter.getSelectors(
      this.featureSelector,
    );
  }

  //////////////////////////////////////////////////////////////////
  // State
  //////////////////////////////////////////////////////////////////
  getInitialState(
    customInitialState: RequiredInitialState<S, GeneralEntityState<E>>,
  ): S {
    this._initialState = {
      ...this.entityAdapter.getInitialState(), // Entity state
      currentPageIds: [],
      selectedItemIds: {},
      selectedId: null,
      pagination: defaultPaginationState,
      query: defaultQueryState,
      ...customInitialState,
    };

    return this._initialState;
  }

  //////////////////////////////////////////////////////////////////
  // Selection state helpers
  //////////////////////////////////////////////////////////////////
  selectCurrentPageIds = () => {
    return createSelector(
      this.featureSelector,
      (state: GeneralEntityState<E>) => state.currentPageIds,
    );
  };

  selectEntityIds = () => {
    return createSelector(
      this.featureSelector,
      (state: GeneralEntityState<E>) => state.ids,
    );
  };

  // TODO: Figure out what MemoizedSelector return types
  selectCurrentPageData = (): MemoizedSelector<any, any> => {
    return createSelector(
      this.entitySelectors.selectEntities,
      this.selectCurrentPageIds(),
      (data, currentPageIds) =>
        currentPageIds.map((id) => data[id]).filter((entity) => !!entity),
    );
  }; // Needed to get data

  //////////////////////////////////////////////////////////////////
  // Query Actions
  //////////////////////////////////////////////////////////////////
  setQuery = createAction(
    `[${this.name}] Set Query`,
    props<{ query: QueryEntityState }>(),
  );
  setHiddenFilterFields = createAction(
    `[${this.name}] Set Hidden Filters`,
    props<{ hiddenFields: QueryField[] }>(),
  ); // Use for all updates to pagination variables
  upsertHiddenFilterFields = createAction(
    `[${this.name}] Upsert Hidden Filters`,
    props<{ hiddenField: QueryField }>(),
  );
  updateQuery = createAction(
    `[${this.name}] Update Query`,
    props<{
      query: Partial<QueryEntityState>;
      pagination?: Partial<PaginationEntityState>;
    }>(),
  ); // Use for all updates to pagination variables
  addSort = createAction(
    `[${this.name}] Add Sort`,
    props<{ sort: SortField }>(),
  ); // Use for all updates to pagination variables

  resetAndUpdateQuery = createAction(
    `[${this.name}] Reset & Update Query`,
    props<{
      query?: Partial<QueryEntityState>;
      pagination?: Partial<PaginationEntityState>;
    }>(),
  );

  //////////////////////////////////////////////////////////////////
  // Query Selectors
  //////////////////////////////////////////////////////////////////

  selectQuery = () =>
    createSelector(
      this.featureSelector,
      (state: GeneralEntityState<E>) => state.query,
    );
  selectFilterFields = () =>
    createSelector(this.selectQuery(), (state) => state.fields);
  selectBaseFilters = () =>
    createSelector(this.selectQuery(), (state) =>
      state.filters.filter((queryFilter) => queryFilter?.isBaseFilter),
    );
  selectSimpleSearch = () =>
    createSelector(this.selectQuery(), (state) => state.searching);
  selectHiddenFilterFields = () =>
    createSelector(this.selectQuery(), (state) => state.hiddenFields);
  selectSorting = () =>
    createSelector(this.selectQuery(), (state) => state.sorting);
  selectSortingItemByField = (field: string) =>
    createSelector(this.selectSorting(), (sorting) =>
      sorting.find((item) => item.field === field),
    );
  selectSortingOrderByField = (field: string) =>
    createSelector(
      this.selectSortingItemByField(field),
      (item) => item?.order || SortOrder.NONE,
    );

  //////////////////////////////////////////////////////////////////
  // Pagination Actions
  //////////////////////////////////////////////////////////////////
  setPagination = createAction(
    `[${this.name}] Set Pagination`,
    props<{ pagination: PaginationEntityState }>(),
  );
  updatePagination = createAction(
    `[${this.name}] Update Pagination`,
    props<{ pagination: Partial<PaginationEntityState> }>(),
  ); // Use for all updates to pagination variables
  incrementPagination = createAction(`[${this.name}] Increment Pagination`); // Use infinite scroll

  //////////////////////////////////////////////////////////////////
  // Pagination Selectors
  //////////////////////////////////////////////////////////////////
  selectPagination = () =>
    createSelector(this.featureSelector, (state) => state.pagination);
  selectCurrentPage = () =>
    createSelector(
      this.selectPagination(),
      (state: PaginationEntityState) => state.page,
    );
  selectPageCount = () =>
    createSelector(
      this.selectPagination(),
      (state: PaginationEntityState) => state.pageCount,
    );
  selectTotalItems = () =>
    createSelector(
      this.selectPagination(),
      (state: PaginationEntityState) => state.total,
    );
  selectPageSize = () =>
    createSelector(
      this.selectPagination(),
      (state: PaginationEntityState) => state.pageSize,
    );

  //////////////////////////////////////////////////////////////////
  // Search Selector
  //////////////////////////////////////////////////////////////////
  selectSearchBody = () =>
    createSelector(
      this.selectQuery(),
      this.selectPagination(),
      (query, pagination) => {
        return {
          query: {
            defaultSearchString: query.defaultSearchString,
            searching: query.searching,
            fields: query.fields,
            hiddenFields: query.hiddenFields,
            sorting: query.sorting,
            filters: query.filters,
          },
          pagination: {
            pageSize: pagination.pageSize,
            page: pagination.page,
            noPaginate: pagination.noPaginate,
          },
        };
      },
    );
  hasSearch = () =>
    createSelector(this.selectQuery(), (query) => query.fields.length > 0);

  //////////////////////////////////////////////////////////////////
  // General Usage Entity Selectors
  //////////////////////////////////////////////////////////////////
  setCurrentlySelectedId = createAction(
    `[${this.name}] Select One`,
    props<{ id: number | string }>(),
  );

  setCurrentPageIds = createAction(
    `[${this.name}] Set Current Page Ids`,
    props<{ currentPageIds: string[] }>(),
  );

  setSelectedItemIds = createAction(
    `[${this.name}] Set Selected Items`,
    props<{ ids: Record<string, boolean> }>(),
  );
  toggleSelectedItemId = createAction(
    `[${this.name}] Select Item`,
    props<{ id: string }>(),
  );
  loadSelectedItemIds = createAction(
    `[${this.name}] Select Items`,
    props<{ ids: Record<string, boolean> }>(),
  );

  selectCurrentlySelectedItemIds = () => {
    return createSelector(
      this.featureSelector,
      (state: GeneralEntityState<E>) => state.selectedItemIds,
    );
  };

  selectIsSelectedItemId = (id: string | number) => {
    return createSelector(
      this.selectCurrentlySelectedItemIds(),
      (state) => state[id] || false,
    );
  };

  selectEntityById = (id: number | string): MemoizedSelector<any, any> => {
    return createSelector(
      this.entitySelectors.selectEntities,
      (entities) => entities[id],
    );
  };

  selectCurrentlySelectedItemIdsAsArray = () => {
    return createSelector(this.selectCurrentlySelectedItemIds(), (state) => {
      const keys = Object.keys(state);
      return keys.filter((key) => state[key]);
    });
  };

  selectCurrentlySelectedItemData = (): MemoizedSelector<any, any> => {
    return createSelector(
      this.entitySelectors.selectEntities,
      this.selectCurrentlySelectedItemIdsAsArray(),
      (data, selectedRowIds) => {
        return selectedRowIds
          .map((id) => data[id])
          .filter((entity) => !!entity);
      },
    );
  };

  selectEntitiesByIds = (ids: (number | string)[]) => {
    return createSelector(this.entitySelectors.selectEntities, (entities) =>
      ids.map((id) => entities[id]),
    );
  };

  selectCurrentlySelectedId = () => {
    return createSelector(this.featureSelector, (state) => state.selectedId);
  };

  selectCurrentlySelectedData = (): MemoizedSelector<any, any> => {
    return createSelector(
      this.selectCurrentlySelectedId(),
      this.entitySelectors.selectEntities,
      (id, entities) => {
        if (id) {
          return {
            id,
            data: entities[id] as E,
          };
        }
        return {
          id,
          data: {},
        };
      },
    );
  };

  //////////////////////////////////////////////////////////////////
  // General Reducer Ons
  //////////////////////////////////////////////////////////////////
  public ons: ReducerTypes<S, ActionCreator[]>[] = [
    // Entity
    on(this.storeActions.addOne, (state, action) =>
      this.entityAdapter.addOne(action.item, state),
    ),
    on(this.storeActions.addMany, (state, action) =>
      this.entityAdapter.addMany(action.items, state),
    ),
    on(this.storeActions.upsertMany, (state, action) =>
      this.entityAdapter.upsertMany(action.items, state),
    ),
    on(this.storeActions.upsertOne, (state, action) =>
      this.entityAdapter.upsertOne(action.item, state),
    ),
    on(this.storeActions.updateOne, (state, action) =>
      this.entityAdapter.updateOne(action.item, state),
    ),
    on(this.storeActions.updateMany, (state, action) =>
      this.entityAdapter.updateMany(action.items, state),
    ),
    // on(this.storeActions.removeOne, (state, action) =>
    //   this.entityAdapter.removeOne(action.item[this.entityIdKey], state),
    // ),
    // on(this.storeActions.removeMany, (state, action) =>
    //   this.entityAdapter.removeMany(
    //     action.items.map((i) => i[this.entityIdKey]),
    //     state,
    //   ),
    // ),
    on(this.storeActions.set, (state, action) =>
      this.entityAdapter.setAll(action.items, state),
    ),

    on(this.storeActions.clear, (state, action) => {
      this.entityAdapter.removeAll(state);
      return {
        ...state,
        currentPageIds: [],
        selectedItemIds: {},
        selectedId: null,
      };
    }),
    on(this.storeActions.upsertMany, (state, action) =>
      this.entityAdapter.upsertMany(action.items, state),
    ),
    // Pagination
    on(this.storeActions.setPage, (state, action) => {
      state = this.entityAdapter.setAll(action.items, state);
      // state = this.entityAdapter.upsertMany(action.items, state);
      return {
        ...state,
        currentPageIds: action.items.map((item) =>
          this.entityAdapter.selectId(item),
        ),
      };
    }),

    on(this.setCurrentlySelectedId, (state, action) => {
      return { ...state, selectedId: action.id };
    }),
    on(this.setCurrentPageIds, (state, action) => {
      return { ...state, currentPageIds: action.currentPageIds };
    }),
    on(this.setSelectedItemIds, (state, action) => {
      return { ...state, selectedItemIds: action.ids };
    }),
    on(this.loadSelectedItemIds, (state, action) => {
      return {
        ...state,
        selectedItemIds: { ...state.selectedItemIds, ...action.ids },
      };
    }),
    on(this.toggleSelectedItemId, (state, action) => {
      const rowSelected = state.selectedItemIds[action.id];
      if (rowSelected) {
        return {
          ...state,
          selectedItemIds: { ...state.selectedItemIds, [action.id]: false },
        };
      } else {
        return {
          ...state,
          selectedItemIds: { ...state.selectedItemIds, [action.id]: true },
        };
      }
    }),

    // Pagination
    on(this.setPagination, (state, action) => {
      return { ...state, pagination: action.pagination };
    }),
    on(this.updatePagination, (state, action) => {
      return {
        ...state,
        pagination: { ...state.pagination, ...action.pagination },
      };
    }),
    on(this.incrementPagination, (state) => {
      const paginationState = state.pagination;
      if (paginationState.page != paginationState.pageCount) {
        return {
          ...state,
          pagination: { ...paginationState, page: paginationState.page + 1 },
        };
      }
      return state;
    }),

    // Search
    on(this.setQuery, (state, action) => {
      return { ...state, query: action.query };
    }),
    on(this.updateQuery, (state, action) => {
      return {
        ...state,
        query: { ...state.query, ...action.query },
        pagination: { ...state.pagination, ...action.pagination },
      };
    }),
    on(this.setHiddenFilterFields, (state, action) => {
      return {
        ...state,
        query: { ...state.query, hiddenFields: action.hiddenFields },
      };
    }),
    on(this.upsertHiddenFilterFields, (state, action) => {
      const hidden: QueryField[] = state.query.hiddenFields;
      const newHiddenField = hidden.filter(
        (v) => v.field !== action.hiddenField.field,
      );
      newHiddenField.push(action.hiddenField);
      return {
        ...state,
        query: { ...state.query, hiddenFields: newHiddenField },
      };
    }),

    // Sorting
    on(this.addSort, (state, action) => {
      // Changed sort functionality to not be additive and to instead replace any existing sorts because
      // it's too confusing even though it is powerful
      // // Remove any existing sorts with that field
      // const sanitized: SortField[] = sorting.components((v) => v.field !== action.sort.field);
      // Remove any existing sorts entirely
      const sanitized: SortField[] = [];
      if (action.sort.order !== SortOrder.NONE) {
        sanitized.push(action.sort);
      }
      return {
        ...state,
        ...this._initialState,
        query: { ...state.query, sorting: sanitized },
      };
    }),

    // Infinite Scrolling
    // NB: This is dangerous for custom state. Make sure you understand that this resets everything.
    on(this.resetAndUpdateQuery, (state, action) => ({
      ...state,
      ...this._initialState,
      query: {
        ...this._initialState.query,
        ...action.query,
      },
      pagination: {
        ...this._initialState.pagination,
        ...action.pagination,
      },
    })),

    on(this.storeActions.addToPage, (state, action) => {
      state = this.entityAdapter.upsertMany(action.items, state);
      let newPageIds: (string | number)[] = [];

      if (action?.addToTop) {
        newPageIds = uniq([
          ...action.items.map((item) => this.entityAdapter.selectId(item)),
          ...state.currentPageIds,
        ]);
      } else {
        newPageIds = uniq([
          ...state.currentPageIds,
          ...action.items.map((item) => this.entityAdapter.selectId(item)),
        ]);
      }
      return {
        ...state,
        currentPageIds: newPageIds,
      };
    }),
  ];
}
