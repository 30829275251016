import {
  MenteeViewTabsEnum,
  MentorViewTabsEnum,
} from "@mentorship/enums/mentorship-tabs.enum";

export const mentorshipTabs = {
  mentorTabsDictionary: {
    0: MentorViewTabsEnum.mentoring,
    1: MentorViewTabsEnum.pendingAndUpcomingSessions,
    2: MentorViewTabsEnum.pastSessions,
  },

  mentorTabs: {
    [MentorViewTabsEnum.mentoring]: 0,
    [MentorViewTabsEnum.pendingAndUpcomingSessions]: 1,
    [MentorViewTabsEnum.pastSessions]: 2,
  },

  menteeTabsDictionary: {
    0: MenteeViewTabsEnum.mentoring,
    1: MenteeViewTabsEnum.mySessions,
    2: MenteeViewTabsEnum.pastSessions,
  },

  menteeTabs: {
    [MenteeViewTabsEnum.mentoring]: 0,
    [MenteeViewTabsEnum.mySessions]: 1,
    [MenteeViewTabsEnum.pastSessions]: 2,
  },
};
