import { Component, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { config } from "@config";
import { requestForgotPasswordLink } from "../../store/auth.actions";
import { environment } from "@environments/environment";
import { ReCaptcha2Component } from "ngx-captcha";
import { Team350Store } from "@shared/store/action-trackers/services/team-350.store";
import { Router } from "@angular/router";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent {
  recaptchaKey: string = environment.siteKey;
  environmentType: boolean = environment.production;

  @ViewChild("reCaptcha2Component")
  // @ts-ignore
  private reCaptcha2Component: ReCaptcha2Component;

  displayMessage: boolean = false;
  constructor(
    private store: Team350Store,
    private router: Router,
  ) {}

  forgotPasswordForm: FormGroup = new FormGroup({
    email: new FormControl("", [
      Validators.required,
      Validators.pattern(config.regex.email),
    ]),
    recaptchaToken: new FormControl(
      "",
      this.environmentType ? [Validators.required] : [],
    ),
  });

  landingRedirect() {
    this.router.navigate(["/landing"]);
  }

  requestPasswordResetLink() {
    this.displayMessage = true;
    const formValues = this.forgotPasswordForm.value;
    this.store.trackDispatch(
      requestForgotPasswordLink({
        email: formValues.email,
        recaptchaToken: formValues.recaptchaToken,
      }),
    );
  }
}
