import { initialRealtimeState } from "src/app/modules/realtime/store/realtime.state";
import { createReducer, on } from "@ngrx/store";
import { loadingState } from "@shared/helpers/loading-state";
import { setSocketId } from "src/app/modules/realtime/store/realtime.actions";

export const realtimeReducer = createReducer(
  initialRealtimeState,
  on(setSocketId, (state, action) => ({
    ...state,
    socketId: action.socketId,
  })),
);
