export enum MenteeViewTabsEnum {
  mentoring = "Mentoring",
  mySessions = "My Sessions",
  pastSessions = "Past Sessions",
}

export enum MentorViewTabsEnum {
  mentoring = "Mentors",
  pendingAndUpcomingSessions = "Pending & Upcoming Sessions",
  pastSessions = "Past Sessions",
}
