<div class="flex flex-col">
  <label class="mb-2" *ngIf="label">{{ label }}</label>
  <span *ngIf="description" class="text-xs mb-1">{{ description }}</span>
  <div
    class="flex flex-col relative"
    [ngClass]="isPrefix ? 'max-w-[8rem]' : ''"
  >
    <div
      id="list"
      (click)="showList = !showList"
      class="dropdown"
      [ngClass]="type"
    >
      <div *ngIf="isSingle" class="single single-dropdown-input">
        <span *ngIf="!optionList" class="placeholder-text">
          {{ placeholder || "Select..." }}
        </span>

        <p
          (click)="$event.stopPropagation(); showList = false"
          *ngIf="optionList"
        >
          {{ optionList[0] ? optionList[0].displayName : "" }}
        </p>
      </div>
      <div *ngIf="!isSingle" class="multi-select-dropdown-input">
        <span
          *ngIf="!optionList || optionList?.length === 0"
          class="placeholder-text pb-2.5"
        >
          {{ placeholder || "Select..." }}
        </span>

        <div
          (click)="$event.stopPropagation(); showList = false"
          class="input-button-tag"
          *ngFor="let item of optionList"
        >
          {{ item.displayName }}
          <span (click)="removeFromList(item)" class="delete-tag-button"
            >&CircleTimes;</span
          >
        </div>
      </div>
      <div class="w-2/12 h-fit text-end">
        <span class="inline-block w-fit mr-2.5 cursor-pointer"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 22 20"
            fill="none"
            class="arrow-button w-4 h-8 sm:w-5 sm:h-5"
          >
            <path
              d="M9.28406 18.134C10.0605 19.4309 11.9395 19.4309 12.7159 18.134L21.3118 3.77739C22.1099 2.44431 21.1496 0.75 19.5958 0.75H2.40419C0.850432 0.75 -0.109913 2.44431 0.688247 3.77739L9.28406 18.134Z"
              fill="#D9D9D9"
            /></svg
        ></span>
      </div>
    </div>

    <div
      [ngClass]="{
        show: showList,
        '': !showList,
        'top-[2.25rem]': isTertiary,
        'top-16': !isTertiary
      }"
      class="dropdown-modal hidden"
    >
      <div class="relative mb-4">
        <div
          class="absolute options inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none"
        >
          <svg
            class="w-4 h-4 text-gray-500"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
        </div>
        <input
          type="text"
          class="search-bar dropdown-search-bar options"
          placeholder="search"
          [(ngModel)]="keyword"
          (keyup)="search(keyword)"
        />
      </div>
      <div>
        <p
          class="options"
          *ngFor="let option of searchList"
          (click)="addListItem(option)"
          (touchstart)="touchStarted()"
          (touchend)="addListItem(option)"
          (touchmove)="touchMoveEventTriggered()"
          [ngClass]="
            optionList && optionList.includes(option)
              ? 'selected'
              : 'font-normal'
          "
        >
          {{ option.optionName }}
        </p>
      </div>
    </div>
  </div>
</div>
