import { GeneralEntityState } from "@shared/store/models/general-entity-state.model";
import { Chat } from "@models";
import { ApiStateHelper } from "@shared/helpers/api-state.helper";
import { Action, createReducer } from "@ngrx/store";
import { CreateChatDto } from "@shared/api-client";
import { defaultPaginationState } from "@shared/store/models/pagination-entity-state.model";
import { customChatReducers } from "./chat.reducer";

export interface ChatState extends GeneralEntityState<Chat> {
  // Insert custom state here
  unreadChatIds: string[];
}

// A helper class that extends the API State Helper with the right information
class ChatStateHelper extends ApiStateHelper<
  Chat,
  CreateChatDto,
  any,
  ChatState
> {
  constructor() {
    super("Chat", "chat", "chatId");
  }
}

export const ChatNgrx = new ChatStateHelper();

export const initialState: ChatState = ChatNgrx.getInitialState({
  unreadChatIds: [],
  pagination: {
    ...defaultPaginationState,
    pageSize: 20,
  },
});

export const chatReducer = createReducer(
  initialState,
  ...ChatNgrx.ons,
  ...ChatNgrx.apiOns,
  ...customChatReducers,
);

export function reducer(
  state: ChatState = initialState,
  action: Action,
): ChatState {
  return chatReducer(state, action) as ChatState;
}
