import { Injectable } from "@angular/core";
import { io, Socket } from "socket.io-client";
import { Team350Store } from "@shared/store/action-trackers/services/team-350.store";
import { websocket } from "@shared/config/websocket.config";
import { forEach } from "lodash";
import { SocketEvent } from "src/app/modules/realtime/enums/socket-event.enum";
import { setSocketId } from "src/app/modules/realtime/store/realtime.actions";
import { RealtimeHandleNewMessageService } from "src/app/modules/realtime/services/realtime-handle-new-message.service";

export type EventMap = { [P in SocketEvent]: (...args: any) => any };

@Injectable({
  providedIn: "root",
})
export class RealtimeClientManagementService {
  private socket: Socket;

  constructor(
    private store: Team350Store,
    private messageService: RealtimeHandleNewMessageService,
  ) {}

  init() {
    this.socket = io();
    this.listenToEvents();
  }

  /*
   * listenToEvents
   * On initialisation, for every socket event, set up a listener based on the socketEventActionMap
   */
  private listenToEvents() {
    // For each event, create socket event listener that triggers corresponding action
    forEach(websocket.events, (value) => {
      this.socket.on(value, (payload) => {
        // `.call(...) ensures that the function in the map is called with the current this context
        // Ensures that `this.store` and other injected services are accessible
        this.socketEventActionMap[value](payload);
      });
    });
  }

  /* On connection set the socket id
   * Using custom connection event because otherwise `socket.on` fails above
   */
  private onConnection = () => {
    this.store.trackDispatch(setSocketId({ socketId: this.socket.id }));
  };

  /*
   * socketEventActionMap
   * A map of socket events to functions that must execute on the event received
   * The functions must be arrow functions to have the correct context
   */
  private socketEventActionMap: EventMap = {
    [SocketEvent.ConnectionHandled]: this.onConnection,
    [SocketEvent.NewMessage]: this.messageService.onNewMessage,
  };
}
