import { NgModule } from "@angular/core";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { SharedModule } from "@shared/shared.module";
import { AuthRoutingModule } from "./auth-routing.module";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { authFeature } from "./store/auth.feature";
import { AuthEffects } from "./store/auth.effects";
import { LogInComponent } from "./components/log-in/log-in.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { NgxCaptchaModule } from "ngx-captcha";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { TakeYourPlaceComponent } from "@auth/components/sign-up/take-your-place-form/take-your-place.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { RefreshTokenInterceptor } from "src/app/modules/auth/interceptors/refresh-token-interceptor.service";
import { UnauthorizedInterceptor } from "src/app/modules/auth/interceptors/unauthorized.interceptor";
import { SignUpApplicationCompleteComponent } from "@auth/components/sign-up/sign-up-application-complete/sign-up-application-complete.component";
import { NgOtpInputModule } from "ng-otp-input";
import { SignUpFormStepperComponent } from "@auth/components/sign-up/sign-up-form-stepper/sign-up-form-stepper.component";
import { BoardhouseFormComponent } from "@auth/components/sign-up/boardhouse-form/boardhouse-form.component";

@NgModule({
  declarations: [
    LogInComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    TakeYourPlaceComponent,
    SignUpApplicationCompleteComponent,
    SignUpFormStepperComponent,
    BoardhouseFormComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AuthRoutingModule,
    StoreModule.forFeature(authFeature),
    EffectsModule.forFeature([AuthEffects]),
    NgxCaptchaModule,
    NgOptimizedImage,
    NgOtpInputModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
  ],
})
export class AuthModule {}
