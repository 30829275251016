<div class="auth-page-container">
  <div class="auth-form-container">
    <img
      class="logo-image"
      src="assets/images/platform-logos/350-club-infinity-dark.svg"
      alt="logo"
    />
    <app-general-card [centeredContent]="true">
      <h1 class="auth-form-title">Reset your password</h1>
      <form class="form" [formGroup]="resetPasswordForm">
        <div class="form-controls-container">
          <input
            class="input"
            type="password"
            formControlName="password"
            placeholder="Enter your password..."
          />
          <span
            class="error-text"
            *ngIf="
              resetPasswordForm.controls['password']?.dirty &&
              resetPasswordForm.controls['password'].errors?.['required']
            "
          >
            Password required
          </span>
          <span
            class="error-text"
            *ngIf="
              resetPasswordForm.controls['password']?.dirty &&
              resetPasswordForm.controls['password'].errors?.['pattern']
            "
          >
            Password should contain atleast 1 digit, 1 lower and uppercase
            letter and minimum length is 8
          </span>

          <input
            class="input"
            type="password"
            formControlName="confirmPassword"
            placeholder="Confirm your password..."
          />
          <span
            class="error-text"
            *ngIf="
              resetPasswordForm.controls['confirmPassword']?.dirty &&
              resetPasswordForm.controls['confirmPassword'].errors?.['required']
            "
          >
            Confirm password required
          </span>
          <span
            class="error-text"
            *ngIf="
              resetPasswordForm.controls['confirmPassword']?.dirty &&
              resetPasswordForm.controls['confirmPassword'].errors?.['confirm']
            "
          >
            The two passwords should match
          </span>
          <span
            class="error-text"
            *ngIf="
              resetPasswordForm.controls['confirmPassword']?.dirty &&
              resetPasswordForm.controls['confirmPassword'].errors?.[
                'minLength'
              ]
            "
          >
            Confirm Password should have a min length of 8
          </span>

          <button
            [disabled]="!resetPasswordForm.valid"
            class="primary-350-button"
            (click)="onResetPassword()"
          >
            Reset Password
          </button>

          <div *ngIf="isResetSuccessful" class="success-text-box">
            <span class="success-text"
              >Your password has been successfully reset. You can now
              <a routerLink="/auth/login" class="underline">login</a></span
            >
          </div>

          <div *ngIf="resetPasswordError" class="failure-text-box">
            <span class="error-text"> {{ resetPasswordError }} </span>
          </div>
        </div>
      </form>
    </app-general-card>
  </div>
</div>
