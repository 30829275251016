import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { config } from "@config";

@Component({
  selector: "app-sign-up-application-complete",
  templateUrl: "./sign-up-application-complete.component.html",
  styleUrls: ["./sign-up-application-complete.component.scss"],
})
export class SignUpApplicationCompleteComponent {
  platformName: string;
  logoUrl: string;
  isBoardhouse: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.route.params.pipe().subscribe((params) => {
      this.platformName = params["platform"];
      const boardhousePlatformFrontendName: string = config.platforms.boardhouse
        .split("-")[0]
        .trim();
      const the350ClubPlatformFrontendName: string =
        config.platforms.team350Club.split("-")[0].trim();

      if (this.platformName === boardhousePlatformFrontendName) {
        this.isBoardhouse = true;
        this.logoUrl =
          "/assets/images/platform-logos/board-house-logo-dark.svg";
      } else if (this.platformName === the350ClubPlatformFrontendName) {
        this.logoUrl =
          "/assets/images/platform-logos/350-club-infinity-light.svg";
      }
    });
  }

  gotoAboutPage() {
    this.router.navigate(["/about"]);
  }

  landingRedirect() {
    this.router.navigate(["/landing"]);
  }
}
