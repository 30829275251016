import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { SelectOptionsClientApiService } from "@shared/api-client";
import {
  getCompanyRoles,
  getCompanyRolesSuccess,
  getLocation,
  getLocationSuccess,
  getSignupCompanyRoles,
  getSignupSelectOptions,
  getSignupSelectOptionsSuccess,
  getUserSkills,
  getUserSkillsSuccess,
} from "./select-options.actions";
import { catchError, of, switchMap } from "rxjs";
import {
  completeActionTracker,
  failActionTracker,
} from "@shared/store/action-trackers/ngrx/action-tracker.actions";

@Injectable()
export class SelectOptionsEffects {
  getSignupSelectOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSignupSelectOptions),
      switchMap((action) =>
        this.selectOptionsApiService
          .selectOptionsControllerGetSignupSelectOptions({
            platformName: action.platformName,
          })
          .pipe(
            switchMap((response) => {
              return [
                getSignupSelectOptionsSuccess({ data: response.data }),
                completeActionTracker({ action, data: response }),
              ];
            }),
            catchError((error) => {
              return of(
                failActionTracker({
                  action,
                  data: { error, message: error?.error.message },
                }),
              );
            }),
          ),
      ),
    ),
  );

  getLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getLocation),
      switchMap((action) =>
        this.selectOptionsApiService.selectOptionsControllerGetLocations().pipe(
          switchMap((response) => {
            return [
              getLocationSuccess({ data: response.data }),
              completeActionTracker({ action, data: response }),
            ];
          }),
        ),
      ),
    ),
  );

  getUserSkills$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserSkills),
      switchMap((action) =>
        this.selectOptionsApiService
          .selectOptionsControllerGetUserSkills()
          .pipe(
            switchMap((response) => {
              return [
                getUserSkillsSuccess({ data: response.data }),
                completeActionTracker({ action, data: response }),
              ];
            }),
            catchError((error) => {
              return of(
                failActionTracker({
                  action,
                  data: { error, message: error?.error.message },
                }),
              );
            }),
          ),
      ),
    ),
  );

  getCompanyRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCompanyRoles),
      switchMap((action) =>
        this.selectOptionsApiService
          .selectOptionsControllerGetPlatformCompanyRoles()
          .pipe(
            switchMap((response) => {
              return [
                getCompanyRolesSuccess({ data: response.data }),
                completeActionTracker({ action, data: response }),
              ];
            }),
            catchError((error) => {
              return of(
                failActionTracker({
                  action,
                  data: { error, message: error?.error.message },
                }),
              );
            }),
          ),
      ),
    ),
  );

  getSignupCompanyRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSignupCompanyRoles),
      switchMap((action) =>
        this.selectOptionsApiService
          .selectOptionsControllerGetCompanyRoles({
            platformName: action.platformName,
          })
          .pipe(
            switchMap((response) => {
              return [
                getCompanyRolesSuccess({ data: response.data }),
                completeActionTracker({ action, data: response }),
              ];
            }),
            catchError((error) => {
              return of(
                failActionTracker({
                  action,
                  data: { error, message: error?.error.message },
                }),
              );
            }),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private selectOptionsApiService: SelectOptionsClientApiService,
  ) {}
}
